import {
  IconDefinition,
  faCloudMoon,
  faCloudRain,
  faCloudSun,
  faHurricane,
  faMoon,
  faSmog,
  faSnowflake,
  faSun,
  faWind,
} from "@fortawesome/free-solid-svg-icons";

import {
  UnixEpoch,
  VisualCrossingWeather,
  VisualCrossingWeatherConditions,
  WeatherZoneDailyObs,
  WeatherZoneDailyObsDay,
  WeatherZoneHourlyObs,
  WeatherZoneLocation,
  WeatherZoneRelatedLocation,
  WeatherZoneWeather,
} from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";
import { WeatherLocation } from "database/ImageHandlerV2";
import _ from "lodash";
import moment from "moment";
import { useState, useEffect, useMemo } from "react";

type Caches = {
  data: {
    [key: string]: WeatherZoneLocation;
  };
  stack: string[];
};

type WeatherInclude = ("current" | "days" | "hours")[];

type UseWeatherOptions = {
  location: WeatherLocation["param"];
  dateRange: UnixEpoch[];
  include?: string;
  cacheKey?: string;
};

type UseWzWeatherOptions = {
  location: WeatherLocation["param"];
  dateRange: UnixEpoch[];
  include?: WeatherInclude;
  cacheKey?: string;
};

type GetWeatherOptions = Omit<UseWeatherOptions, "location" | "dateRange"> & {
  timeZone?: string;
  stationId?: string;
};

type GetWzWeatherOptions = Omit<
  UseWzWeatherOptions,
  "location" | "dateRange"
> & {
  timeZone?: string;
  stationId?: string;
};

export type WeatherStation = string;

export type AggregatedWeatherDayData = {
  wzData: WeatherZoneDailyObsDay;
  vcData: VisualCrossingWeatherConditions;
};

export type AggregatedWeatherHourData = {
  wzData: WeatherZoneHourlyObs;
  vcData: VisualCrossingWeatherConditions;
};

export type AggregatedWeatherData = {
  wzData: WeatherZoneLocation & {
    related_location?: WeatherZoneRelatedLocation;
  };
  vcData: Omit<VisualCrossingWeather, "days" | "currentConditions">;
  days?: AggregatedWeatherDayData[];
  hours?: AggregatedWeatherHourData[];
  current?: AggregatedWeatherHourData;
};

export type FaWeatherIconName =
  | "snow"
  | "rain"
  | "fog"
  | "wind"
  | "gust"
  | "cloudy"
  | "partly-cloudy-day"
  | "partly-cloudy-night"
  | "clear-day"
  | "clear-night";

const vcCaches: Caches = {
  data: {},
  stack: [],
};

const wzCaches: Caches = {
  data: {},
  stack: [],
};

const Cache = {
  MAX_CACHES: 15,
  get: <T,>(caches: Caches, cacheKey: string) => {
    return _.get(caches, ["data", cacheKey]) as unknown as T | undefined;
  },
  set: <T,>(caches: Caches, cacheKey: string, data: T) => {
    _.set(caches, ["data", cacheKey], data);

    caches.stack.push(cacheKey);

    if (caches.stack.length > Cache.MAX_CACHES) {
      _.unset(caches, ["data", caches.stack.shift()] as string[]);
    }
  },
};

const getDateString = (timeStamp: string) => {
  return moment.parseZone(timeStamp).format("YYYY-MM-DDTHH:mm");
};

const parseWeatherZoneHourData = (
  hourData: WeatherZoneHourlyObs[],
  from,
  to,
) => {
  /*
    some hour might be missing from weatherZone, so we insert the missing hour with null or a default value as a quick fix.
    the reason for this is becuz we r using the index when combinging with visual cross data.
    Eg: wzData[0] is same hour as vzData[0]
  */

  const formatString = "YYYY-MM-DDTHH:mm";

  const mapper = _.keyBy<WeatherZoneHourlyObs>(hourData, (item) =>
    getDateString(item.local_time),
  );

  const startHour = moment(from).startOf("day").format(formatString);
  const lastHour = moment(to).endOf("day").format(formatString);

  const total: WeatherZoneHourlyObs[] = [];

  const interval = 30;

  for (
    const m = moment(startHour, formatString);
    m.isSameOrBefore(moment(lastHour, formatString));
    m.add(interval, "minutes")
  ) {
    const currentHour = m.format(formatString);

    const defaultItem = hourData[0];
    const currentTimeMmt = moment(currentHour, formatString).tz(
      defaultItem.time_zone,
      true,
    );

    // Not sure which is good: just return null or insert a default object with null value
    total.push(
      mapper[currentHour] || {
        related_location: defaultItem.related_location,
        timestamp: currentTimeMmt.clone().unix().toString(),
        utc_time: currentTimeMmt.clone().utc().format(),
        local_time: currentTimeMmt.clone().format(),
        tz: defaultItem.tz,
        time_zone: defaultItem.time_zone,
        temperature: null,
        feels_like: null,
        dew_point: null,
        relative_humidity: null,
        wind_direction: null,
        wind_direction_compass: null,
        wind_speed: null,
        wind_gust: null,
        avg_wind_speed_last_10_minutes: null,
        max_wind_gust_last_10_minutes: null,
        rainfall_since_9am: null,
        pressure: null,
        _isEmpty: true,
      },
    );
  }

  const filtered = total.filter(
    // keep only the sharp hourly data, from 00:00:00 to 23:00:00
    (i, index) => index % 2 === 0,
  );

  return filtered;
};

const getWzWeatherBatch = async (
  location: WeatherLocation["param"],
  dateRange: UnixEpoch[],
  options: GetWzWeatherOptions = {},
) => {
  // weatherZone has a return limit on its historical hours, so we send request for each date and join them toegether to by pass it

  const [from, to] = dateRange;

  const weatherPromises: Promise<WeatherZoneLocation | null | undefined>[] = [];

  for (
    const m = moment.unix(from);
    m.isSameOrBefore(moment.unix(to || from));
    m.add(1, "days")
  ) {
    weatherPromises.push(getWzWeather(location, [m.unix()], options));
  }

  return await Promise.all(weatherPromises).then((batchData) => {
    if (batchData && batchData[0]) {
      const restData = _.omit(batchData[0], [
        "current",
        "daily_observations",
        "historical_observation",
      ]);

      const combinedData: WeatherZoneLocation = {
        ...restData,
      };

      batchData.forEach((data) => {
        if (data?.daily_observations) {
          combinedData.daily_observations ||= {
            ...data.daily_observations,
            days: [],
          };

          combinedData.daily_observations.days.push(
            ...data.daily_observations.days,
          );
        }

        if (data?.historical_observation) {
          combinedData.historical_observation ||= [];

          combinedData.historical_observation.push(
            ...data.historical_observation,
          );
        }

        if (data?.current) {
          combinedData.current = data.current;
        }
      });

      return combinedData;
    }
  });
};

const getWzWeather = async (
  location: WeatherLocation["param"],
  dateRange: UnixEpoch[],
  options: GetWzWeatherOptions = {},
) => {
  /*
    Get weather from both visual cross and weather zone, then combining both data.
  */

  if (location) {
    const { cacheKey, timeZone } = options;

    const include = options.include || ["current"];

    const parsedDateRange = dateRange.map((d) =>
      moment
        .unix(d)
        .tz(options.timeZone || "UTC")
        .format("YYYY-MM-DD"),
    );

    const from = parsedDateRange[0];
    const to = parsedDateRange[1] || from;

    const parsedCacheKey =
      cacheKey || [location, from, to, timeZone].filter((v) => v).join(":");

    const cacheData = Cache.get<WeatherZoneLocation>(wzCaches, parsedCacheKey);

    if (cacheData) {
      if (
        (include.includes("days") && cacheData.daily_observations) ||
        (include.includes("hours") && cacheData.historical_observation)
      ) {
        return cacheData;
      } else if (
        include.includes("current") &&
        cacheData.historical_observation
      ) {
        const currentHour = moment
          .unix(dateRange[1] || dateRange[0])
          .tz(cacheData.time_zone)
          .hour();

        const currentData = cacheData.historical_observation[currentHour];

        if (currentData) {
          return {
            ...cacheData,
            current: currentData,
          };
        }
      }
    }

    const [lat, lon] = location.split(",");

    const payloads = {
      lat,
      lon,
    };

    if (include.includes("days")) {
      payloads["dailyobs"] = `1(from=${from},to=${to},sun_hours=1, solar=1)`;
    }

    // if days we nid to get its hours too for calculating wind info
    if (
      include.includes("days") ||
      include.includes("hours") ||
      include.includes("current")
    ) {
      payloads["histobs"] = `1(from=${from},to=${to},interval=30min,order=asc)`;
      // interval 30mins so that the first data start from 00:00:00, otherwise it start from 00:30:00 and end at 23:30:00 for some reason.
    }

    try {
      return await getFirebaseController()
        .Callable.getWzWeather({
          params: payloads,
        })
        .then((results) => {
          if (results.status) {
            const data = results.data.countries?.[0]?.locations?.[0];

            if (data.historical_observation) {
              data.historical_observation = parseWeatherZoneHourData(
                data.historical_observation,
                from,
                to,
              );

              if (include.includes("current")) {
                const currentHour = moment
                  .unix(dateRange[1] || dateRange[0])
                  .tz(data.time_zone)
                  .hour();

                // sometime the current hour data might not be populated in wz yet, so get the closest past data
                let index = currentHour;
                let foundData;

                while (!foundData && index !== 0) {
                  const selectData = data.historical_observation?.[index];

                  if (!selectData._isEmpty) {
                    foundData = selectData;
                  }

                  index -= 1;
                }

                data.current = foundData;
              }
            }

            if (data.daily_observations) {
              const chunckHours = _.chunk(data.historical_observation, 24);

              data.daily_observations.days = data.daily_observations.days.map(
                (day, dayIndex) => {
                  const dayHours = chunckHours[dayIndex];

                  let maxWindSpeed;
                  let maxWindGust;
                  let maxWindDirection;
                  let maxWindDirectionCompass;

                  if (dayHours) {
                    dayHours.forEach((hour) => {
                      if (Number(hour.wind_speed) > (maxWindSpeed || 0)) {
                        maxWindSpeed = hour.wind_speed;
                        maxWindDirection = hour.wind_direction;
                        maxWindDirectionCompass = hour.wind_direction_compass;
                      }

                      if (Number(hour.wind_gust) > (maxWindGust || 0)) {
                        maxWindGust = hour.wind_gust;
                      }
                    });
                  }

                  return {
                    ...day,
                    _max_wind_speed: maxWindSpeed,
                    _max_wind_gust: maxWindGust,
                    _max_wind_direction: maxWindDirection,
                    _max_wind_direction_compass: maxWindDirectionCompass,
                  };
                },
              );
            }

            Cache.set(
              wzCaches,
              parsedCacheKey,
              cacheData ? { ...cacheData, ...data } : data,
            );

            return data;
          }
        });
    } catch (error) {
      console.error(error);

      return null;
    }
  } else {
    return null;
  }
};

const getVcWeather = async (
  location: string,
  dateRange: UnixEpoch[],
  options: GetWzWeatherOptions = {},
) => {
  if (location && dateRange.length > 0) {
    const { include = "current", timeZone, cacheKey, stationId } = options;

    const requestLocationPath = `${
      stationId ? `stn:${stationId}` : location
    }/${dateRange.join("/")}`;

    const parsedCacheKey =
      cacheKey || `${requestLocationPath}/${include}/${timeZone}`;

    const cacheData = Cache.get<VisualCrossingWeather>(
      vcCaches,
      parsedCacheKey,
    );

    if (cacheData) {
      return cacheData;
    }

    try {
      const includes = [include, "obs", "remote", "fcst"];

      const payload = {
        include: includes.join(","),
        location,
      };

      if (dateRange[0]) {
        payload["date1"] = dateRange[0];
      }

      if (dateRange[1]) {
        payload["date2"] = dateRange[1];
      }

      if (timeZone) {
        payload["timeZone"] = timeZone;
      }

      if (stationId) {
        payload["maxStations"] = "1";
      }

      const res = await getFirebaseController().Callable.getVcWeather({
        params: payload,
      });

      if (res.status) {
        Cache.set(vcCaches, parsedCacheKey, res.data);

        return res.data;
      }
    } catch (error) {
      return null;
    }
  }
};

export const useWeather = (options?: UseWzWeatherOptions) => {
  const [data, setData] = useState<AggregatedWeatherData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>("");

  const getWeather = async (
    location: WeatherLocation["param"],
    dateRange: UnixEpoch[],
    options: GetWzWeatherOptions = {},
  ): Promise<AggregatedWeatherData | void> => {
    options.include ||= ["current"];

    if (!location || !dateRange) {
      return;
    }

    setIsLoading(true);
    setError("");
    setData(null);

    return await Promise.all([
      getWzWeatherBatch(location, dateRange, options),
      getVcWeather(location, dateRange, options),
    ])
      .then(([wz, vc]) => {
        if (wz && vc) {
          const {
            current: wzCurrent,
            daily_observations: wzDaily,
            historical_observation: wzHourly,
            ...restWzData
          } = wz;

          const {
            currentConditions: vcCurrent,
            days: vcDays = [],
            ...restVcData
          } = vc;

          const returnData: AggregatedWeatherData = {
            wzData: {
              ...restWzData,
              related_location: wzDaily?.related_location,
            },
            vcData: restVcData,
          };

          if (options.include!.includes("days")) {
            const days = wzDaily?.days.map((day, dayIndex) => {
              return {
                wzData: day,
                vcData: vcDays[dayIndex],
              };
            });

            returnData["days"] = days;
          }

          if (options.include!.includes("hours")) {
            const hours = wzHourly?.reduce<AggregatedWeatherHourData[]>(
              (hourData, hour, hourIndex) => {
                const vcDayIndex = Math.floor(hourIndex / 24);
                const vcHourIndex = hourIndex % 24;

                const vcHour = vcDays[vcDayIndex]?.hours?.[vcHourIndex];

                if (vcHour) {
                  hourData.push({
                    wzData: hour,
                    vcData: vcHour,
                  });
                }

                return hourData;
              },
              [],
            );

            returnData["hours"] = hours;
          }

          if (options.include!.includes("current")) {
            if (wzCurrent && vcCurrent) {
              returnData["current"] = {
                wzData: wzCurrent,
                vcData: vcCurrent,
              };
            }
          }

          setIsLoading(false);
          setError("");
          setData(returnData);

          return returnData;
        }
      })
      .catch((err) => {
        console.error(err);

        setIsLoading(false);
        setError(err);
        setData(null);
      });
  };

  useEffect(() => {
    const { location, dateRange, ...restOptions } = options || {};

    if (location && dateRange) {
      getWeather(location, dateRange, restOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return useMemo(() => {
    return { data, isLoading, error, getWeather };
  }, [data, isLoading, error]);
};

export const getFaWeatherColor = (weather: FaWeatherIconName): string => {
  switch (weather) {
    case "snow":
      return "#03a9f4";
    case "rain":
      return "#03a9f4";
    case "fog":
      return "#2ec1ff";
    case "wind":
      return "#def2ff";
    case "cloudy":
    case "partly-cloudy-day":
      return "#f28e02";
    case "partly-cloudy-night":
      return "#05304c";
    case "clear-day":
      return "#f28e02";
    case "clear-night":
      return "#05304c";
    default:
      return "";
  }
};

export const getFaWeatherIcon = (
  weather: FaWeatherIconName,
): IconDefinition | undefined => {
  switch (weather) {
    case "snow":
      return faSnowflake;
    case "rain":
      return faCloudRain;
    case "fog":
      return faSmog;
    case "wind":
      return faWind;
    case "gust":
      return faHurricane;
    case "cloudy":
    case "partly-cloudy-day":
      return faCloudSun;
    case "partly-cloudy-night":
      return faCloudMoon;
    case "clear-day":
      return faSun;
    case "clear-night":
      return faMoon;
    default:
      return undefined;
  }
};

export default useWeather;

export const testHourData = [
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732798800",
      utc_time: "2024-11-28T13:00:00Z",
      local_time: "2024-11-29T00:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 18.1,
      feels_like: 19.3,
      dew_point: 15.9,
      relative_humidity: 87,
      wind_direction: 222,
      wind_direction_compass: "SW",
      wind_speed: 4,
      wind_gust: 9,
      avg_wind_speed_last_10_minutes: 4,
      max_wind_gust_last_10_minutes: 9,
      rainfall_since_9am: 0.2,
      pressure: 1016,
    },
    vcData: {
      datetime: "00:00:00",
      datetimeEpoch: 1732798800,
      temp: 18,
      feelslike: 18,
      humidity: 83.11,
      dew: 15,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 10.4,
      windspeed: 10.2,
      winddir: 204,
      pressure: 1017,
      visibility: 10,
      cloudcover: 54.6,
      solarradiation: 0,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-night",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732802400",
      utc_time: "2024-11-28T14:00:00Z",
      local_time: "2024-11-29T01:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 17.4,
      feels_like: 18.9,
      dew_point: 15.7,
      relative_humidity: 90,
      wind_direction: 150,
      wind_direction_compass: "SE",
      wind_speed: 2,
      wind_gust: 6,
      avg_wind_speed_last_10_minutes: 2,
      max_wind_gust_last_10_minutes: 6,
      rainfall_since_9am: 0.2,
      pressure: 1016,
    },
    vcData: {
      datetime: "01:00:00",
      datetimeEpoch: 1732802400,
      temp: 17,
      feelslike: 17,
      humidity: 88.02,
      dew: 15,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 8.6,
      windspeed: 8.5,
      winddir: 204,
      pressure: 1017,
      visibility: 10,
      cloudcover: 100,
      solarradiation: 0,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 10,
      conditions: "Overcast",
      icon: "cloudy",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732806000",
      utc_time: "2024-11-28T15:00:00Z",
      local_time: "2024-11-29T02:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 17,
      feels_like: 17.9,
      dew_point: 15.9,
      relative_humidity: 93,
      wind_direction: 158,
      wind_direction_compass: "S",
      wind_speed: 6,
      wind_gust: 6,
      avg_wind_speed_last_10_minutes: 6,
      max_wind_gust_last_10_minutes: 6,
      rainfall_since_9am: 0.2,
      pressure: 1016,
    },
    vcData: {
      datetime: "02:00:00",
      datetimeEpoch: 1732806000,
      temp: 16.1,
      feelslike: 16.1,
      humidity: 93.25,
      dew: 15,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 10.4,
      windspeed: 8.4,
      winddir: 222,
      pressure: 1016.1,
      visibility: 10,
      cloudcover: 100,
      solarradiation: 0,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 10,
      conditions: "Overcast",
      icon: "cloudy",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732809600",
      utc_time: "2024-11-28T16:00:00Z",
      local_time: "2024-11-29T03:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 16.8,
      feels_like: 17.6,
      dew_point: 15.8,
      relative_humidity: 94,
      wind_direction: 192,
      wind_direction_compass: "S",
      wind_speed: 6,
      wind_gust: 6,
      avg_wind_speed_last_10_minutes: 6,
      max_wind_gust_last_10_minutes: 6,
      rainfall_since_9am: 0.2,
      pressure: 1015,
    },
    vcData: {
      datetime: "03:00:00",
      datetimeEpoch: 1732809600,
      temp: 16.1,
      feelslike: 16.1,
      humidity: 93.25,
      dew: 15,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 10.8,
      windspeed: 9.9,
      winddir: 267,
      pressure: 1016,
      visibility: 10,
      cloudcover: 89.1,
      solarradiation: 0,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-night",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732813200",
      utc_time: "2024-11-28T17:00:00Z",
      local_time: "2024-11-29T04:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 16.2,
      feels_like: 18,
      dew_point: 15.4,
      relative_humidity: 95,
      wind_direction: 0,
      wind_direction_compass: "N",
      wind_speed: 0,
      wind_gust: 0,
      avg_wind_speed_last_10_minutes: 0,
      max_wind_gust_last_10_minutes: 0,
      rainfall_since_9am: 0.2,
      pressure: 1015,
    },
    vcData: {
      datetime: "04:00:00",
      datetimeEpoch: 1732813200,
      temp: 16.1,
      feelslike: 16.1,
      humidity: 93.25,
      dew: 15,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 8.6,
      windspeed: 10.2,
      winddir: 267,
      pressure: 1016,
      visibility: 10,
      cloudcover: 89.1,
      solarradiation: 0,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-night",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732816800",
      utc_time: "2024-11-28T18:00:00Z",
      local_time: "2024-11-29T05:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 15.7,
      feels_like: 17.5,
      dew_point: 15.4,
      relative_humidity: 98,
      wind_direction: 0,
      wind_direction_compass: "N",
      wind_speed: 0,
      wind_gust: 0,
      avg_wind_speed_last_10_minutes: 0,
      max_wind_gust_last_10_minutes: 0,
      rainfall_since_9am: 0.2,
      pressure: 1016,
    },
    vcData: {
      datetime: "05:00:00",
      datetimeEpoch: 1732816800,
      temp: 16.1,
      feelslike: 16.1,
      humidity: 87.94,
      dew: 14.1,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 4.7,
      windspeed: 10.2,
      winddir: 276,
      pressure: 1016,
      visibility: 10,
      cloudcover: 100,
      solarradiation: 0,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 10,
      conditions: "Overcast",
      icon: "cloudy",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732820400",
      utc_time: "2024-11-28T19:00:00Z",
      local_time: "2024-11-29T06:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 15.5,
      feels_like: 17.2,
      dew_point: 15.2,
      relative_humidity: 98,
      wind_direction: 0,
      wind_direction_compass: "N",
      wind_speed: 0,
      wind_gust: 0,
      avg_wind_speed_last_10_minutes: 0,
      max_wind_gust_last_10_minutes: 0,
      rainfall_since_9am: 0.2,
      pressure: 1016,
    },
    vcData: {
      datetime: "06:00:00",
      datetimeEpoch: 1732820400,
      temp: 16.1,
      feelslike: 16.1,
      humidity: 93.25,
      dew: 15,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 2.9,
      windspeed: 10.4,
      winddir: 268,
      pressure: 1016.1,
      visibility: 10,
      cloudcover: 31.8,
      solarradiation: 2,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-day",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732824000",
      utc_time: "2024-11-28T20:00:00Z",
      local_time: "2024-11-29T07:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 16.4,
      feels_like: 18.4,
      dew_point: 16.1,
      relative_humidity: 98,
      wind_direction: 0,
      wind_direction_compass: "N",
      wind_speed: 0,
      wind_gust: 0,
      avg_wind_speed_last_10_minutes: 0,
      max_wind_gust_last_10_minutes: 0,
      rainfall_since_9am: 0.2,
      pressure: 1017,
    },
    vcData: {
      datetime: "07:00:00",
      datetimeEpoch: 1732824000,
      temp: 17,
      feelslike: 17,
      humidity: 93.3,
      dew: 15.9,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 3.6,
      windspeed: 8.4,
      winddir: 295,
      pressure: 1017,
      visibility: 10,
      cloudcover: 54.6,
      solarradiation: 41,
      solarenergy: 0.1,
      uvindex: 0,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-day",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732827600",
      utc_time: "2024-11-28T21:00:00Z",
      local_time: "2024-11-29T08:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 18.2,
      feels_like: 20.6,
      dew_point: 17,
      relative_humidity: 93,
      wind_direction: 0,
      wind_direction_compass: "N",
      wind_speed: 0,
      wind_gust: 2,
      avg_wind_speed_last_10_minutes: 0,
      max_wind_gust_last_10_minutes: 2,
      rainfall_since_9am: 0.2,
      pressure: 1017,
    },
    vcData: {
      datetime: "08:00:00",
      datetimeEpoch: 1732827600,
      temp: 19,
      feelslike: 19,
      humidity: 87.67,
      dew: 16.9,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 2.2,
      windspeed: 10.9,
      winddir: 255,
      pressure: 1017,
      visibility: 10,
      cloudcover: 89.1,
      solarradiation: 45,
      solarenergy: 0.2,
      uvindex: 0,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-day",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732831200",
      utc_time: "2024-11-28T22:00:00Z",
      local_time: "2024-11-29T09:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 20.8,
      feels_like: 22.4,
      dew_point: 16.8,
      relative_humidity: 78,
      wind_direction: 99,
      wind_direction_compass: "E",
      wind_speed: 4,
      wind_gust: 9,
      avg_wind_speed_last_10_minutes: 4,
      max_wind_gust_last_10_minutes: 9,
      rainfall_since_9am: 0.2,
      pressure: 1017,
    },
    vcData: {
      datetime: "09:00:00",
      datetimeEpoch: 1732831200,
      temp: 20,
      feelslike: 20,
      humidity: 77.76,
      dew: 15.9,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 3.6,
      windspeed: 7.4,
      winddir: 155,
      pressure: 1017.9,
      visibility: 10,
      cloudcover: 89.1,
      solarradiation: 342,
      solarenergy: 1.2,
      uvindex: 3,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-day",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732834800",
      utc_time: "2024-11-28T23:00:00Z",
      local_time: "2024-11-29T10:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 22.3,
      feels_like: 23.5,
      dew_point: 16.8,
      relative_humidity: 71,
      wind_direction: 75,
      wind_direction_compass: "E",
      wind_speed: 6,
      wind_gust: 13,
      avg_wind_speed_last_10_minutes: 6,
      max_wind_gust_last_10_minutes: 13,
      rainfall_since_9am: 0,
      pressure: 1017,
    },
    vcData: {
      datetime: "10:00:00",
      datetimeEpoch: 1732834800,
      temp: 21.9,
      feelslike: 21.9,
      humidity: 69.55,
      dew: 16,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 5,
      windspeed: 9.6,
      winddir: 325,
      pressure: 1017,
      visibility: 10,
      cloudcover: 31.8,
      solarradiation: 617,
      solarenergy: 2.2,
      uvindex: 6,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-day",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732838400",
      utc_time: "2024-11-29T00:00:00Z",
      local_time: "2024-11-29T11:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 24.3,
      feels_like: 25.8,
      dew_point: 17.5,
      relative_humidity: 66,
      wind_direction: 63,
      wind_direction_compass: "NE",
      wind_speed: 6,
      wind_gust: 11,
      avg_wind_speed_last_10_minutes: 6,
      max_wind_gust_last_10_minutes: 11,
      rainfall_since_9am: 0,
      pressure: 1017,
    },
    vcData: {
      datetime: "11:00:00",
      datetimeEpoch: 1732838400,
      temp: 23.9,
      feelslike: 23.9,
      humidity: 65.28,
      dew: 17,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 9.4,
      windspeed: 4.8,
      winddir: 92,
      pressure: 1017,
      visibility: 10,
      cloudcover: 30.7,
      solarradiation: 831,
      solarenergy: 3,
      uvindex: 8,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-day",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732842000",
      utc_time: "2024-11-29T01:00:00Z",
      local_time: "2024-11-29T12:00:00+1100",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: 26.4,
      feels_like: 28.2,
      dew_point: 18.3,
      relative_humidity: 61,
      wind_direction: 223,
      wind_direction_compass: "SW",
      wind_speed: 6,
      wind_gust: 11,
      avg_wind_speed_last_10_minutes: 6,
      max_wind_gust_last_10_minutes: 11,
      rainfall_since_9am: 0,
      pressure: 1016,
    },
    vcData: {
      datetime: "12:00:00",
      datetimeEpoch: 1732842000,
      temp: 25.7,
      feelslike: 25.7,
      humidity: 55.27,
      dew: 16,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 13,
      windspeed: 9.9,
      winddir: 139,
      pressure: 1016.1,
      visibility: 10,
      cloudcover: 25,
      solarradiation: 921,
      solarenergy: 3.3,
      uvindex: 9,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-day",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732845600",
      utc_time: "2024-11-29T02:00:00Z",
      local_time: "2024-11-29T13:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "13:00:00",
      datetimeEpoch: 1732845600,
      temp: 28.3,
      feelslike: 27.8,
      humidity: 38.41,
      dew: 12.8,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 13,
      windspeed: 3.6,
      winddir: 228.6,
      pressure: 1014,
      visibility: 24.1,
      cloudcover: 0,
      solarradiation: 1041,
      solarenergy: 3.7,
      uvindex: 10,
      severerisk: 10,
      conditions: "Clear",
      icon: "clear-day",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732849200",
      utc_time: "2024-11-29T03:00:00Z",
      local_time: "2024-11-29T14:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "14:00:00",
      datetimeEpoch: 1732849200,
      temp: 29.1,
      feelslike: 28.2,
      humidity: 34.79,
      dew: 12,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 12.2,
      windspeed: 3.2,
      winddir: 311.9,
      pressure: 1014,
      visibility: 24.1,
      cloudcover: 0,
      solarradiation: 1051,
      solarenergy: 3.8,
      uvindex: 10,
      severerisk: 10,
      conditions: "Clear",
      icon: "clear-day",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732852800",
      utc_time: "2024-11-29T04:00:00Z",
      local_time: "2024-11-29T15:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "15:00:00",
      datetimeEpoch: 1732852800,
      temp: 29.7,
      feelslike: 28.6,
      humidity: 32.3,
      dew: 11.4,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 9.4,
      windspeed: 4.7,
      winddir: 311.5,
      pressure: 1013,
      visibility: 24.1,
      cloudcover: 0,
      solarradiation: 998,
      solarenergy: 3.6,
      uvindex: 10,
      severerisk: 10,
      conditions: "Clear",
      icon: "clear-day",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732856400",
      utc_time: "2024-11-29T05:00:00Z",
      local_time: "2024-11-29T16:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "16:00:00",
      datetimeEpoch: 1732856400,
      temp: 29.8,
      feelslike: 28.6,
      humidity: 31.69,
      dew: 11.2,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 9.7,
      windspeed: 4.3,
      winddir: 269.2,
      pressure: 1012,
      visibility: 24.1,
      cloudcover: 8.9,
      solarradiation: 889,
      solarenergy: 3.2,
      uvindex: 9,
      severerisk: 10,
      conditions: "Clear",
      icon: "clear-day",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732860000",
      utc_time: "2024-11-29T06:00:00Z",
      local_time: "2024-11-29T17:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "17:00:00",
      datetimeEpoch: 1732860000,
      temp: 29.2,
      feelslike: 28.4,
      humidity: 35.51,
      dew: 12.4,
      precip: 0,
      precipprob: 0,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 9.7,
      windspeed: 7.2,
      winddir: 196.7,
      pressure: 1011,
      visibility: 24.1,
      cloudcover: 38.5,
      solarradiation: 729,
      solarenergy: 2.6,
      uvindex: 7,
      severerisk: 10,
      conditions: "Partially cloudy",
      icon: "partly-cloudy-day",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732863600",
      utc_time: "2024-11-29T07:00:00Z",
      local_time: "2024-11-29T18:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "18:00:00",
      datetimeEpoch: 1732863600,
      temp: 27.4,
      feelslike: 27.5,
      humidity: 45.51,
      dew: 14.6,
      precip: 0,
      precipprob: 29,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 12.2,
      windspeed: 10.8,
      winddir: 170.9,
      pressure: 1012,
      visibility: 24.1,
      cloudcover: 100,
      solarradiation: 444,
      solarenergy: 1.6,
      uvindex: 4,
      severerisk: 10,
      conditions: "Overcast",
      icon: "cloudy",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732867200",
      utc_time: "2024-11-29T08:00:00Z",
      local_time: "2024-11-29T19:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "19:00:00",
      datetimeEpoch: 1732867200,
      temp: 24.9,
      feelslike: 24.9,
      humidity: 59.22,
      dew: 16.4,
      precip: 0,
      precipprob: 29,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 22.3,
      windspeed: 12.6,
      winddir: 155.8,
      pressure: 1012,
      visibility: 24.1,
      cloudcover: 100,
      solarradiation: 221,
      solarenergy: 0.8,
      uvindex: 2,
      severerisk: 10,
      conditions: "Overcast",
      icon: "cloudy",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732870800",
      utc_time: "2024-11-29T09:00:00Z",
      local_time: "2024-11-29T20:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "20:00:00",
      datetimeEpoch: 1732870800,
      temp: 21.9,
      feelslike: 21.9,
      humidity: 71.42,
      dew: 16.5,
      precip: 0,
      precipprob: 29,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 18.7,
      windspeed: 8.3,
      winddir: 152.5,
      pressure: 1013,
      visibility: 24.1,
      cloudcover: 100,
      solarradiation: 71,
      solarenergy: 0.3,
      uvindex: 1,
      severerisk: 10,
      conditions: "Overcast",
      icon: "cloudy",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732874400",
      utc_time: "2024-11-29T10:00:00Z",
      local_time: "2024-11-29T21:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "21:00:00",
      datetimeEpoch: 1732874400,
      temp: 19.7,
      feelslike: 19.7,
      humidity: 80.75,
      dew: 16.3,
      precip: 0,
      precipprob: 29,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 13.7,
      windspeed: 7.6,
      winddir: 191.7,
      pressure: 1013,
      visibility: 24.1,
      cloudcover: 100,
      solarradiation: 0,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 3,
      conditions: "Overcast",
      icon: "cloudy",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732878000",
      utc_time: "2024-11-29T11:00:00Z",
      local_time: "2024-11-29T22:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "22:00:00",
      datetimeEpoch: 1732878000,
      temp: 18.4,
      feelslike: 18.4,
      humidity: 87.01,
      dew: 16.2,
      precip: 0,
      precipprob: 29,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 10.4,
      windspeed: 6.1,
      winddir: 220.2,
      pressure: 1013,
      visibility: 24.1,
      cloudcover: 100,
      solarradiation: 0,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 5,
      conditions: "Overcast",
      icon: "cloudy",
      stations: null,
      source: "fcst",
    },
  },
  {
    wzData: {
      related_location: {
        type: "SITE",
        code: "86068",
        name: "Viewbank",
        state: "VIC",
      },
      timestamp: "1732881600",
      utc_time: "2024-11-29T12:00:00Z",
      local_time: "2024-11-29T23:00:00+11:00",
      tz: "EDT",
      time_zone: "Australia/Melbourne",
      temperature: null,
      feels_like: null,
      dew_point: null,
      relative_humidity: null,
      wind_direction: null,
      wind_direction_compass: null,
      wind_speed: null,
      wind_gust: null,
      avg_wind_speed_last_10_minutes: null,
      max_wind_gust_last_10_minutes: null,
      rainfall_since_9am: null,
      pressure: null,
      _isEmpty: true,
    },
    vcData: {
      datetime: "23:00:00",
      datetimeEpoch: 1732881600,
      temp: 17.7,
      feelslike: 17.7,
      humidity: 90.35,
      dew: 16.1,
      precip: 0,
      precipprob: 29,
      snow: 0,
      snowdepth: 0,
      preciptype: null,
      windgust: 11.5,
      windspeed: 6.1,
      winddir: 241.7,
      pressure: 1013,
      visibility: 24.1,
      cloudcover: 100,
      solarradiation: 0,
      solarenergy: 0,
      uvindex: 0,
      severerisk: 5,
      conditions: "Overcast",
      icon: "cloudy",
      stations: null,
      source: "fcst",
    },
  },
];

export const testWeatherData = [
  {
    wzData: {
      day_name: "Friday",
      date: "2024-11-22",
      min: 13.3,
      min_anomaly: 1.9,
      max: 37.5,
      max_anomaly: 14.1,
      rainfall: null,
      rainfall_period: " hours to 9am",
      insolation: 26.7,
      _max_wind_speed: 15,
      _max_wind_gust: 24,
      _max_wind_direction: 333,
      _max_wind_direction_compass: "NW",
    },
    vcData: {
      datetime: "2024-11-22",
      datetimeEpoch: 1732194000,
      tempmax: 35.7,
      tempmin: 12.8,
      temp: 25.1,
      feelslikemax: 33.5,
      feelslikemin: 12.8,
      feelslike: 24.2,
      dew: 9.2,
      humidity: 43.8,
      precip: 0,
      precipprob: 0,
      precipcover: 0,
      preciptype: null,
      snow: 0,
      snowdepth: 0,
      windgust: 31,
      windspeed: 30,
      winddir: 350,
      pressure: 1017.8,
      cloudcover: 77,
      visibility: 12.4,
      solarradiation: 277.5,
      solarenergy: 23.9,
      uvindex: 10,
      severerisk: 10,
      sunrise: "05:55:29",
      sunriseEpoch: 1732215329,
      sunset: "20:16:39",
      sunsetEpoch: 1732266999,
      moonphase: 0.7,
      conditions: "Partially cloudy",
      description: "Partly cloudy throughout the day.",
      icon: "partly-cloudy-day",
      stations: ["YMAV", "C7520", "YMML", "remote"],
      source: "obs",
    },
  },
  {
    wzData: {
      day_name: "Saturday",
      date: "2024-11-23",
      min: 21.4,
      min_anomaly: 10,
      max: 37.5,
      max_anomaly: 14.1,
      rainfall: 0,
      rainfall_period: "24 hours to 9am",
      insolation: 19.1,
      _max_wind_speed: 22,
      _max_wind_gust: 44,
      _max_wind_direction: 321,
      _max_wind_direction_compass: "NW",
    },
    vcData: {
      datetime: "2024-11-23",
      datetimeEpoch: 1732280400,
      tempmax: 36,
      tempmin: 21,
      temp: 28,
      feelslikemax: 33.7,
      feelslikemin: 21,
      feelslike: 27.2,
      dew: 9.3,
      humidity: 34.1,
      precip: 0.1,
      precipprob: 100,
      precipcover: 4.17,
      preciptype: ["rain"],
      snow: 0,
      snowdepth: 0,
      windgust: 50,
      windspeed: 39.2,
      winddir: 345.6,
      pressure: 1014.9,
      cloudcover: 89.1,
      visibility: 10,
      solarradiation: 218.2,
      solarenergy: 18.8,
      uvindex: 8,
      severerisk: 10,
      sunrise: "05:54:59",
      sunriseEpoch: 1732301699,
      sunset: "20:17:42",
      sunsetEpoch: 1732353462,
      moonphase: 0.75,
      conditions: "Rain, Partially cloudy",
      description: "Partly cloudy throughout the day with late afternoon rain.",
      icon: "rain",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      day_name: "Sunday",
      date: "2024-11-24",
      min: 18.6,
      min_anomaly: 7.2,
      max: 22.6,
      max_anomaly: -0.8,
      rainfall: 5,
      rainfall_period: "24 hours to 9am",
      insolation: 7.5,
      _max_wind_speed: 9,
      _max_wind_gust: 15,
      _max_wind_direction: 195,
      _max_wind_direction_compass: "S",
    },
    vcData: {
      datetime: "2024-11-24",
      datetimeEpoch: 1732366800,
      tempmax: 21.7,
      tempmin: 16.9,
      temp: 19.5,
      feelslikemax: 21.7,
      feelslikemin: 16.9,
      feelslike: 19.5,
      dew: 17.3,
      humidity: 87.8,
      precip: 29.5,
      precipprob: 100,
      precipcover: 66.67,
      preciptype: ["rain"],
      snow: 0,
      snowdepth: 0,
      windgust: 22.7,
      windspeed: 17.2,
      winddir: 171.9,
      pressure: 1016.2,
      cloudcover: 94.2,
      visibility: 8.4,
      solarradiation: 55.8,
      solarenergy: 4.9,
      uvindex: 3,
      severerisk: 10,
      sunrise: "05:54:31",
      sunriseEpoch: 1732388071,
      sunset: "20:18:45",
      sunsetEpoch: 1732439925,
      moonphase: 0.77,
      conditions: "Rain, Overcast",
      description:
        "Cloudy skies throughout the day with a chance of rain throughout the day.",
      icon: "rain",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      day_name: "Monday",
      date: "2024-11-25",
      min: 16.6,
      min_anomaly: 5.2,
      max: 18.7,
      max_anomaly: -4.7,
      rainfall: 9.2,
      rainfall_period: "24 hours to 9am",
      insolation: 6.3,
      _max_wind_speed: 11,
      _max_wind_gust: 19,
      _max_wind_direction: 195,
      _max_wind_direction_compass: "S",
    },
    vcData: {
      datetime: "2024-11-25",
      datetimeEpoch: 1732453200,
      tempmax: 17.1,
      tempmin: 15.9,
      temp: 16.6,
      feelslikemax: 17.1,
      feelslikemin: 15.9,
      feelslike: 16.6,
      dew: 15.9,
      humidity: 95.9,
      precip: 7.7,
      precipprob: 100,
      precipcover: 58.33,
      preciptype: ["rain"],
      snow: 0,
      snowdepth: 0,
      windgust: 21.6,
      windspeed: 18,
      winddir: 211.5,
      pressure: 1013.3,
      cloudcover: 98.2,
      visibility: 4.2,
      solarradiation: 36.8,
      solarenergy: 3.3,
      uvindex: 1,
      severerisk: 10,
      sunrise: "05:54:04",
      sunriseEpoch: 1732474444,
      sunset: "20:19:47",
      sunsetEpoch: 1732526387,
      moonphase: 0.8,
      conditions: "Rain, Overcast",
      description:
        "Cloudy skies throughout the day with a chance of rain throughout the day.",
      icon: "rain",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      day_name: "Tuesday",
      date: "2024-11-26",
      min: 16.8,
      min_anomaly: 5.4,
      max: 29.6,
      max_anomaly: 6.2,
      rainfall: 5.8,
      rainfall_period: "24 hours to 9am",
      insolation: 18.8,
      _max_wind_speed: 9,
      _max_wind_gust: 19,
      _max_wind_direction: 265,
      _max_wind_direction_compass: "W",
    },
    vcData: {
      datetime: "2024-11-26",
      datetimeEpoch: 1732539600,
      tempmax: 26.8,
      tempmin: 17,
      temp: 20.8,
      feelslikemax: 27.7,
      feelslikemin: 17,
      feelslike: 20.9,
      dew: 16.9,
      humidity: 80.1,
      precip: 4.8,
      precipprob: 100,
      precipcover: 37.5,
      preciptype: ["rain"],
      snow: 0,
      snowdepth: 0,
      windgust: 22.7,
      windspeed: 22.2,
      winddir: 199.9,
      pressure: 1007.5,
      cloudcover: 89.8,
      visibility: 6.8,
      solarradiation: 203.4,
      solarenergy: 17.5,
      uvindex: 10,
      severerisk: 30,
      sunrise: "05:53:40",
      sunriseEpoch: 1732560820,
      sunset: "20:20:48",
      sunsetEpoch: 1732612848,
      moonphase: 0.83,
      conditions: "Rain, Partially cloudy",
      description: "Partly cloudy throughout the day with rain.",
      icon: "rain",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      day_name: "Wednesday",
      date: "2024-11-27",
      min: 18,
      min_anomaly: 6.6,
      max: 30.6,
      max_anomaly: 7.2,
      rainfall: 32.6,
      rainfall_period: "24 hours to 9am",
      insolation: 19.6,
      _max_wind_speed: 22,
      _max_wind_gust: 39,
      _max_wind_direction: 351,
      _max_wind_direction_compass: "N",
    },
    vcData: {
      datetime: "2024-11-27",
      datetimeEpoch: 1732626000,
      tempmax: 31,
      tempmin: 16,
      temp: 22.1,
      feelslikemax: 31.3,
      feelslikemin: 16,
      feelslike: 22.3,
      dew: 17.5,
      humidity: 77.2,
      precip: 17.9,
      precipprob: 100,
      precipcover: 66.67,
      preciptype: ["rain"],
      snow: 0,
      snowdepth: 0,
      windgust: 57.6,
      windspeed: 38.4,
      winddir: 3.2,
      pressure: 1001.9,
      cloudcover: 75.7,
      visibility: 9.9,
      solarradiation: 226.2,
      solarenergy: 19.5,
      uvindex: 10,
      severerisk: 30,
      sunrise: "05:53:18",
      sunriseEpoch: 1732647198,
      sunset: "20:21:49",
      sunsetEpoch: 1732699309,
      moonphase: 0.86,
      conditions: "Rain, Partially cloudy",
      description:
        "Partly cloudy throughout the day with a chance of rain throughout the day.",
      icon: "rain",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      day_name: "Thursday",
      date: "2024-11-28",
      min: 14.6,
      min_anomaly: 3.2,
      max: 26.3,
      max_anomaly: 2.9,
      rainfall: 11.8,
      rainfall_period: "24 hours to 9am",
      insolation: null,
      _max_wind_speed: 15,
      _max_wind_gust: 33,
      _max_wind_direction: 213,
      _max_wind_direction_compass: "SW",
    },
    vcData: {
      datetime: "2024-11-28",
      datetimeEpoch: 1732712400,
      tempmax: 24,
      tempmin: 15,
      temp: 19.5,
      feelslikemax: 24,
      feelslikemin: 15,
      feelslike: 19.5,
      dew: 13.8,
      humidity: 71.7,
      precip: 0.3,
      precipprob: 100,
      precipcover: 12.5,
      preciptype: ["rain"],
      snow: 0,
      snowdepth: 0,
      windgust: 24.1,
      windspeed: 29.4,
      winddir: 170.7,
      pressure: 1013,
      cloudcover: 61.8,
      visibility: 10,
      solarradiation: 275.9,
      solarenergy: 23.8,
      uvindex: 10,
      severerisk: 10,
      sunrise: "05:52:58",
      sunriseEpoch: 1732733578,
      sunset: "20:22:49",
      sunsetEpoch: 1732785769,
      moonphase: 0.9,
      conditions: "Rain, Partially cloudy",
      description: "Partly cloudy throughout the day with rain clearing later.",
      icon: "rain",
      stations: ["YMAV", "C7520", "YMML"],
      source: "obs",
    },
  },
  {
    wzData: {
      day_name: "Friday",
      date: "2024-11-29",
      min: 15.4,
      min_anomaly: 4,
      max: 27.3,
      max_anomaly: 3.9,
      rainfall: 0.2,
      rainfall_period: "24 hours to 9am",
      insolation: null,
      _max_wind_speed: 6,
      _max_wind_gust: 13,
      _max_wind_direction: 158,
      _max_wind_direction_compass: "S",
    },
    vcData: {
      datetime: "2024-11-29",
      datetimeEpoch: 1732798800,
      tempmax: 29.8,
      tempmin: 16.1,
      temp: 21.6,
      feelslikemax: 28.6,
      feelslikemin: 16.1,
      feelslike: 21.4,
      dew: 14.9,
      humidity: 70.3,
      precip: 0,
      precipprob: 29,
      precipcover: 0,
      preciptype: null,
      snow: 0,
      snowdepth: 0,
      windgust: 22.3,
      windspeed: 12.6,
      winddir: 225.1,
      pressure: 1014.8,
      cloudcover: 63.8,
      visibility: 16.5,
      solarradiation: 343.5,
      solarenergy: 29.6,
      uvindex: 10,
      severerisk: 10,
      sunrise: "05:52:39",
      sunriseEpoch: 1732819959,
      sunset: "20:23:48",
      sunsetEpoch: 1732872228,
      moonphase: 0.93,
      conditions: "Partially cloudy",
      description: "Clearing in the afternoon.",
      icon: "partly-cloudy-day",
      stations: ["YMAV", "C7520", "YMML"],
      source: "comb",
    },
  },
];
