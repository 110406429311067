import { memo, useEffect, useMemo, useState } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  matchPath,
  NavLink,
  Link,
} from "react-router-dom";

import { useSetAtom, useAtomValue, useAtom } from "jotai";
import { currentClientState, userRolesState } from "states/auth";
import { clientListCacheState, deviceListCacheState } from "states/caches";
import { isSidenavShowState, themeSettingsState } from "states/layout";
import { searchState, currentDeviceState } from "states/navbar";

import {
  useMediaQuery,
  Box,
  Menu,
  TextField,
  Divider,
  MenuItem,
  Grid,
  IconButton,
  Typography,
  Icon,
  Container,
  InputAdornment,
  Theme,
  lighten,
  darken,
  alpha,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import ReliveItLogo from "assets/BEERAssets/ReliveIt/Relive-IT-Logo-White.png";

import { Client, DeviceV2 } from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";

import { useAuth } from "context/Auth/AuthContext";
import useAccess from "hooks/useAccess";

import _ from "lodash";
import { orderByIgnoreCase } from "utils/display";

export interface NavbarProps {
  title?: string;
  isShowClient?: boolean;
  isShowDevice?: boolean;
  disableMenuToggle?: boolean;
}

const Navbar = memo(
  ({
    title,
    isShowClient = false,
    isShowDevice = false,
    disableMenuToggle = false,
  }: NavbarProps) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const setOnSearch = useSetAtom(searchState);

    const currentClient = useAtomValue(currentClientState);
    const [currentDevice] = useAtom(currentDeviceState);

    const userRoles = useAtomValue(userRolesState);
    const [isSidenavShow, setIsSidenavShow] = useAtom(isSidenavShowState);

    const [dropdownList, setDropdownList] = useState<(Client | DeviceV2)[]>([]);

    const [clientMenuAnchorEl, setClientMenuAnchorEl] =
      useState<null | HTMLElement>(null);

    const { isAdmin } = useAccess();
    const { handleUserRoleChanged, handleClientChanged } = useAuth();

    const [clientListCache, setClientListCache] = useAtom(clientListCacheState);
    const [deviceListCache, setDeviceListCache] = useAtom(deviceListCacheState);

    const smallScreenAndUp = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up("sm"),
    );

    const navMenu = [
      ...(isAdmin
        ? [
            {
              name: "Devices",
              key: "devices",
              route: "/devices",
            },
            {
              name: "Galleries",
              key: "galleries",
              route: "/galleries",
            },
            {
              name: "Clients",
              key: "clients",
              route: "/clients",
            },
            {
              name: "Users",
              key: "users",
              route: "/users",
            },
            {
              name: "Job Sites",
              key: "job_sites",
              route: "/job-sites",
            },
          ]
        : [
            {
              name: "Active",
              key: "active",
              route: "/galleries",
            },
            {
              name: "Archive",
              key: "archive",
              route: "/galleries?archive=true",
              params: "archive=true",
            },
          ]),
    ];

    const [currentRouteKey, setCurrentRouteKey] = useState("");

    useEffect(() => {
      /*
        TODO: try implement a better solution to deal with the params and the active nav item
        /galleries -> galleries
        /galleries?random=params -> galleries
        /galleries?archive=true -> archive
        /galleris?archive=false -> active
        /galleris -> active 
       */

      let matchRouteKey;

      navMenu.find((menu) => {
        const path = `${location.pathname}${location.search}`;

        const isMatched = !!matchPath(`${menu.route}/*`, path);

        if (isMatched) {
          matchRouteKey = menu.key;
        }

        return isMatched;
      });

      if (!matchRouteKey) {
        navMenu.find((menu) => {
          const path = `${location.pathname}`;

          const isMatched = !!matchPath(`${menu.route}/*`, path);

          if (isMatched) {
            matchRouteKey = menu.key;
          }

          return isMatched;
        });
      }

      setCurrentRouteKey(matchRouteKey);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const handleSearch = useMemo(() => {
      return _.debounce((e) => {
        return setOnSearch((prev) => ({
          value: e.target.value,
          onSearch: !prev.onSearch,
        }));
      }, 500);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearchEnter = (e) => {
      if (e.key === "Enter") {
        handleSearch(e);
        handleSearch.flush();
      }
    };

    const isClientMenuOpen = Boolean(clientMenuAnchorEl);

    const initClientsDropdown = async () => {
      if (clientListCache[0]) {
        const sortedList = orderByIgnoreCase(clientListCache, "name");

        setDropdownList(sortedList);

        if (isAdmin) {
          // auto select the first one for admin user
          handleClientChanged(sortedList[0]);
        }

        return;
      }

      const promises: Promise<any>[] = [];

      const firebaseController = getFirebaseController();

      if (isAdmin) {
        promises.push(firebaseController.Client.getClients());
      } else {
        promises.push(
          firebaseController.Client.getClients({
            ids: userRoles.map((role) => role.associatedClient as number),
          }),
        );
      }

      Promise.all(promises).then(([data]) => {
        const sortedData = orderByIgnoreCase(data, "name");

        setDropdownList(sortedData as Client[]);
        setClientListCache(sortedData as Client[]);
      });
    };

    const initDevicesDropdown = async () => {
      if (deviceListCache[0]) {
        setDropdownList(deviceListCache);

        return;
      }

      const promises: Promise<any>[] = [];

      promises.push(getFirebaseController().Device.getDevices());

      Promise.all(promises).then(([data]) => {
        setDropdownList(data as DeviceV2[]);
        setDeviceListCache(data);
      });
    };

    useEffect(() => {
      if (isShowClient) {
        initClientsDropdown();
      }

      if (isShowDevice) {
        initDevicesDropdown();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowClient, isShowDevice, isAdmin]);

    const [clientSearchInput, setClientSearchInput] = useState("");

    const displayDropdownList = () => {
      if (clientSearchInput) {
        const searchTexts = _.toLower(clientSearchInput).split(" ");

        return dropdownList.filter((item) => {
          const fields: string[] = [];

          if (isShowClient) {
            const client = item as Client;

            const siteName = _.toLower(client.name);

            fields.push(siteName);
          } else if (isShowDevice) {
            const device = item as DeviceV2;

            fields.push(
              _.toLower(device.friendlyName),
              _.toLower(device.deviceId),
            );
          }

          return searchTexts.every((s) => {
            return fields.some((f) => {
              return f.includes(s);
            });
          });
        });
      } else {
        return dropdownList;
      }
    };

    const handleMenuItemClick = (item: Client | DeviceV2) => {
      if (isShowClient) {
        const client = item as Client;

        if (client.id) {
          if (isAdmin) {
            handleClientChanged(client);
          } else {
            const userRole = userRoles.find(
              (userRole) => userRole.associatedClient === client.id,
            );
            if (userRole) {
              handleUserRoleChanged(userRole.id);
            }
          }
        }
      } else if (isShowDevice) {
        const device = item as DeviceV2;

        if (device.id) {
          const path = location.pathname.replace(
            `${params.id}`,
            `${device.id}`,
          );

          navigate(path);
        }
      }

      setClientMenuAnchorEl(null);
    };

    const themeSettings = useAtomValue(themeSettingsState);

    const logo = useMemo(() => {
      return themeSettings?.logoUrl || ReliveItLogo;
    }, [themeSettings]);

    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: ({ zIndex }) => zIndex.appBar,
        }}
      >
        {(isShowDevice || (isShowClient && isAdmin)) && (
          <Menu
            anchorEl={clientMenuAnchorEl}
            open={isClientMenuOpen}
            onClose={() => {
              setClientMenuAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              ".MuiMenu-paper": {
                minWidth: 250,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                boxShadow: 3,
                overflow: "hidden",
              },
            }}
          >
            <Box sx={{ px: 1 }}>
              <TextField
                size="small"
                fullWidth
                placeholder="Search"
                value={clientSearchInput}
                onChange={(e) => setClientSearchInput(e.target.value)}
              />

              <Divider
                sx={{
                  mt: 1,
                }}
              />
            </Box>

            <Box
              sx={{
                maxHeight: 450,
                overflow: "auto",
              }}
            >
              {displayDropdownList().map((item) => {
                const getDisplayText = (): string => {
                  let displayText: any = "";

                  if (isShowClient) {
                    const client = item as Client;

                    displayText = client.name || client.id;
                  } else if (isShowDevice) {
                    const device = item as DeviceV2;

                    displayText =
                      device.friendlyName || device.deviceId || device.id;
                  }

                  return displayText.toString();
                };

                return (
                  <MenuItem
                    disabled={disableMenuToggle}
                    key={item.id}
                    selected={
                      item.id ===
                      (isShowClient ? currentClient?.id : currentDevice?.id)
                    }
                    onClick={() => {
                      handleMenuItemClick(item);
                    }}
                  >
                    {getDisplayText()}
                  </MenuItem>
                );
              })}
            </Box>
          </Menu>
        )}

        <Box
          sx={{
            backgroundColor: ({ palette }) => palette.secondary.main,
            height: ({ customConfig }) => customConfig.navbarHeight,
            color: ({ palette }) => alpha(palette.onSecondary.main, 0.5),

            borderBottom: ({ palette }) =>
              `1px solid ${alpha(palette.onSecondary.main, 0.5)}`,

            zIndex: 1,
            position: "relative",
          }}
        >
          <Grid container alignItems={"center"} height={"100%"}>
            <Grid
              item
              xs={1}
              sm={4}
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ mx: 2 }}>
                <IconButton
                  color="inherit"
                  disableRipple
                  sx={{ p: 0 }}
                  onClick={() => setIsSidenavShow(!isSidenavShow)}
                >
                  <MenuIcon fontSize="medium" />
                </IconButton>
              </Box>

              {smallScreenAndUp && (
                <Box sx={{ height: "100%" }}>
                  <Box
                    component={Link}
                    to={"/"}
                    sx={{
                      py: 1,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      crossOrigin="anonymous"
                      component={"img"}
                      src={logo}
                      sx={{
                        width: "auto",
                        height: "100%",
                        cursor: "pointer",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={10}
              sm={4}
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                onClick={(e) => {
                  setClientMenuAnchorEl(e.currentTarget);
                }}
                sx={{
                  cursor: "pointer",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  color: ({ palette }) =>
                    isClientMenuOpen
                      ? palette.onSecondary.main
                      : darken(palette.onSecondary.main, 0.1),

                  ".MuiIconButton-root": {
                    color: ({ palette }) =>
                      isClientMenuOpen
                        ? palette.onSecondary.main
                        : darken(palette.onSecondary.main, 0.1),

                    transition: ({ transitions }) =>
                      transitions.create("color"),
                  },

                  transition: ({ transitions }) => transitions.create("color"),

                  ":hover": {
                    color: ({ palette }) => palette.onSecondary.main,

                    ".MuiIconButton-root": {
                      color: ({ palette }) => palette.onSecondary.main,
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    lineHeight: 1,
                  }}
                >
                  {(isShowClient
                    ? currentClient?.name
                    : isShowDevice
                    ? currentDevice?.friendlyName || currentDevice?.deviceId
                    : title) || ""}
                </Typography>

                {((isShowDevice && currentDevice) ||
                  (isShowClient && currentClient)) &&
                  dropdownList.length > 1 && (
                    <IconButton
                      color="inherit"
                      disableRipple
                      sx={{ p: 0, pl: 1 }}
                    >
                      <Icon fontSize="small">keyboard_arrow_down</Icon>
                    </IconButton>
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            backgroundColor: ({ palette }) => palette.tertiary.main,
            height: ({ customConfig }) => customConfig.subNavbarHeight,
            zIndex: 0,

            borderBottom: ({ palette }) =>
              `1px solid ${alpha(palette.onTertiary.main, 0.3)}`,
            boxShadow: 3,
          }}
        >
          <Container fixed maxWidth={"xl"} sx={{ height: "100%" }}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ height: "100%", gap: 2, flexWrap: "nowrap" }}
            >
              {smallScreenAndUp && (
                <Grid
                  item
                  sx={{ height: "100%", width: "100%" }}
                  xs={12}
                  sm={8}
                  md={9}
                >
                  <Grid
                    container
                    justifyContent={"space-between"}
                    height={"100%"}
                    spacing={0}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        sx={{
                          height: "100%",
                          flexWrap: "nowrap",
                          overflow: "auto",
                          WebkitOverflowScrolling: "touch",
                          scrollbarWidth: "none",
                        }}
                      >
                        {navMenu.map((menu, menuIndex) => {
                          const isActive = currentRouteKey === menu.key;

                          return (
                            <Grid
                              item
                              key={menuIndex}
                              sx={{ height: "100%", minWidth: 120 }}
                            >
                              <Box
                                sx={({ palette }) => ({
                                  height: "100%",
                                  width: "100%",
                                  backgroundColor: isActive
                                    ? palette.primary.main
                                    : "transparent",
                                  color: isActive
                                    ? palette.onPrimary.main
                                    : palette.onTertiary.main,

                                  borderRight:
                                    menuIndex === navMenu.length - 1
                                      ? 0
                                      : `1px solid ${alpha(
                                          palette.onTertiary.main,
                                          0.2,
                                        )}`,

                                  ":hover": {
                                    backgroundColor: isActive
                                      ? palette.primary.main
                                      : darken(palette.tertiary.main, 0.05),
                                  },
                                })}
                              >
                                <NavLink
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "inherit",
                                  }}
                                  to={menu.route}
                                  key={menu.key}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      lineHeight: 1,
                                      color: "inherit",
                                    }}
                                  >
                                    {_.upperCase(menu.name)}
                                  </Typography>
                                </NavLink>
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  name="search"
                  type="search"
                  fullWidth
                  size="small"
                  variant={"outlined"}
                  placeholder="Search"
                  inputProps={{
                    autoComplete: "off",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    ".MuiInputBase-root": {
                      backgroundColor: ({ palette }) => palette.surface.main,
                      color: ({ palette }) => palette.onSurface.main,
                    },
                  }}
                  onChange={handleSearch}
                  onKeyDown={handleSearchEnter}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);

export default Navbar;
