import _ from "lodash";
import {
  Comment,
  DeviceV2,
  DeviceV2Details,
  DeviceV2IntervalSettings,
  DeviceV2Settings,
  GalleryV2,
  ImageExtras,
  IntervalV2,
  JobSite,
  Client,
  User,
  UserRole,
  Rotate,
  Version,
  GalleryDeviceV2Settings,
  AdminEvent,
  DeviceV2Info,
  PhotoSentielImage,
} from "./DataTypes";

export const DAY_NAMES: [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const UPDATE_CHANNEL_MAPPER = {
  0: "Production",
  1: "Beta",
};

export const AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR = {
  0: "Auto",
  1: "Tungsten",
  2: "Fluorescent",
  3: "Indoor",
  4: "Daylight",
  5: "Cloudy",
  7: "Custom",
};

export const DEFAULT_IMAGE_TAGS = ["Rain Impact", "Hero Image"];

export const ROTATE_VALUE_MAPPER: { [key in Rotate]: number } = {
  0: 0,
  1: 90,
  2: 180,
  3: 270,
};

export const INTERVAL_TIME_OPTIONS = [
  { label: "00:00", value: "00:00" },
  { label: "00:30", value: "00:30" },
  { label: "01:00", value: "01:00" },
  { label: "01:30", value: "01:30" },
  { label: "02:00", value: "02:00" },
  { label: "02:30", value: "02:30" },
  { label: "03:00", value: "03:00" },
  { label: "03:30", value: "03:30" },
  { label: "04:00", value: "04:00" },
  { label: "04:30", value: "04:30" },
  { label: "05:00", value: "05:00" },
  { label: "05:30", value: "05:30" },
  { label: "06:00", value: "06:00" },
  { label: "06:30", value: "06:30" },
  { label: "07:00", value: "07:00" },
  { label: "07:30", value: "07:30" },
  { label: "08:00", value: "08:00" },
  { label: "08:30", value: "08:30" },
  { label: "09:00", value: "09:00" },
  { label: "09:30", value: "09:30" },
  { label: "10:00", value: "10:00" },
  { label: "10:30", value: "10:30" },
  { label: "11:00", value: "11:00" },
  { label: "11:30", value: "11:30" },
  { label: "12:00", value: "12:00" },
  { label: "12:30", value: "12:30" },
  { label: "13:00", value: "13:00" },
  { label: "13:30", value: "13:30" },
  { label: "14:00", value: "14:00" },
  { label: "14:30", value: "14:30" },
  { label: "15:00", value: "15:00" },
  { label: "15:30", value: "15:30" },
  { label: "16:00", value: "16:00" },
  { label: "16:30", value: "16:30" },
  { label: "17:00", value: "17:00" },
  { label: "17:30", value: "17:30" },
  { label: "18:00", value: "18:00" },
  { label: "18:30", value: "18:30" },
  { label: "19:00", value: "19:00" },
  { label: "19:30", value: "19:30" },
  { label: "20:00", value: "20:00" },
  { label: "20:30", value: "20:30" },
  { label: "21:00", value: "21:00" },
  { label: "21:30", value: "21:30" },
  { label: "22:00", value: "22:00" },
  { label: "22:30", value: "22:30" },
  { label: "23:00", value: "23:00" },
  { label: "23:30", value: "23:30" },
  { label: "23:59", value: "23:59" },
];

export const UPDATE_TIME_OPTIONS = [
  { label: "00:00", value: "00:00" },
  { label: "01:00", value: "01:00" },
  { label: "02:00", value: "02:00" },
  { label: "03:00", value: "03:00" },
  { label: "04:00", value: "04:00" },
  { label: "05:00", value: "05:00" },
  { label: "06:00", value: "06:00" },
  { label: "07:00", value: "07:00" },
  { label: "08:00", value: "08:00" },
  { label: "09:00", value: "09:00" },
  { label: "10:00", value: "10:00" },
  { label: "11:00", value: "11:00" },
  { label: "12:00", value: "12:00" },
  { label: "13:00", value: "13:00" },
  { label: "14:00", value: "14:00" },
  { label: "15:00", value: "15:00" },
  { label: "16:00", value: "16:00" },
  { label: "17:00", value: "17:00" },
  { label: "18:00", value: "18:00" },
  { label: "19:00", value: "19:00" },
  { label: "20:00", value: "20:00" },
  { label: "21:00", value: "21:00" },
  { label: "22:00", value: "22:00" },
  { label: "23:00", value: "23:00" },
];

export const INTERVAL_OPTIONS = [
  { label: "5 Minutes", value: 300 },
  { label: "10 Minutes", value: 600 },
  { label: "20 Minutes", value: 1200 },
  { label: "30 Minutes", value: 1800 },
  { label: "60 Minutes", value: 3600 },
];

export const BURST_INTERVAL_OPTIONS = [
  { label: "5 Seconds", value: 5 },
  { label: "10 Seconds", value: 10 },
  { label: "15 Seconds", value: 15 },
  { label: "20 Seconds", value: 20 },
  { label: "30 Seconds", value: 30 },
  { label: "1 Minute", value: 60 },
  { label: "2 Minutes", value: 120 },
  { label: "5 Minutes", value: 300 },
  { label: "10 Minutes", value: 600 },
  { label: "20 Minutes", value: 1200 },
  { label: "30 Minutes", value: 1800 },
  { label: "60 Minutes", value: 3600 },
];

export const IMAGE_HOST_MAPPER = {
  "0": "Firebase",
  "1": "Dropbox",
  "2": "photoSentinel",
  "3": "Amazon",
  "4": "Other",
};

export const IMAGE_HOST_DIRECTORY_OPTIONS_MAPPER = {
  "0": ["/"],
  "1": ["/Apps/Infinity Image Sync/", "/Apps/photoSentinel/", "/"],
  "2": ["/"],
  "3": ["/"],
  "4": ["/"],
};

export const ROLE_ACCESS_LEVEL_MAPPER = {
  "0": "Full",
  "1": "View Only",
  "2": "None",
  "3": "Admin",
  "4": "Full - With HD",
};

export const ROLE_ACCESS_LEVEL_DISPLAY_ORDER = {
  "0": 2,
  "1": 3,
  "2": 4,
  "3": 0,
  "4": 1,
};

export const CHANNEL_MAPPER = {
  0: "production",
  1: "beta",
};

export const BRIGHTNESS_RANGE = [-1.0, 1.0];
export const CONTRAST_RANGE = [0.0, 32.0];
export const SATURATION_RANGE = [0.0, 32.0];
export const SHARPNESS_RANGE = [0.0, 16.0];

const DEFAULT_USER: User = {
  id: null,
  userId: null,
  username: "",
  email: "",
  userWebAppSettings: null,
};

const DEFAULT_USER_ROLE: UserRole = {
  id: null,
  accessLevel: "1",
  associatedClient: null,
  accessableJobSites: [],
  associatedUser: null,
  isInheritSites: false,
  restrictedGalleries: [],
};

const DEFAULT_IMAGE_EXTRAS: ImageExtras = {
  id: null,
  deviceId: null,
  galleryId: null,
  externalFileName: "",
  imageApplied: "",
  favorited: false,
  hidden: false,
  markup: "",
  comments: [],
  tags: [],
  associatedUser: null,
  associatedClient: null,
};

const DEFAULT_COMMENT: Comment = {
  id: null,
  commentedUserId: null,
  epochTime: 0,
  comment: "",
};

const DEFAULT_INTERVAL: IntervalV2 = {
  active: true,
  burst: false,
  burstTimeBetweenShots: BURST_INTERVAL_OPTIONS[0].value,
  burstTimeFrom: INTERVAL_TIME_OPTIONS[0].value,
  burstTimeTo: INTERVAL_TIME_OPTIONS[0].value,
  timeBetweenShots: INTERVAL_OPTIONS[1].value,
  timeFrom: "07:00",
  timeTo: "17:00",
};

const DEFAULT_DEVICE: DeviceV2 = {
  id: null,
  assignedGallery: null,
  battery: 0,
  current: 0,
  deg90Camera: true,
  deviceId: "",
  friendlyName: "",
  frontendName: "",
  humidity: 0,
  lastReported: "",
  lastReportedLocal: "",
  latestImage: null,
  photoCount: 0,
  photoUploads: 0,
  setupMode: false,
  solar: 0,
  storagePer: 0,
  temperature: 0,
};

const DEFAULT_DEVICE_DETAILS: DeviceV2Details = {
  altitude: "",
  ethIP: "Disconnected",
  lastPhoto: "",
  latitude: "",
  longitude: "",
  mcuVersion: "",
  nextPhoto: "",
  pressure: 0,
  publicIP: "",
  setupMode: false,
  signalStrength: 0,
  storageFree: 0,
  storageMax: 0,
  timeZone: "",
  twoStepFocusValue: 0,
  version: "",
  vpnIP: "Disconnected",
  wakeUpTime: "",
};

const DEFAULT_DEVICE_SETTINGS: DeviceV2Settings = {
  autoExposure: false,
  autoFocus: false,
  autoWhiteBalance: true,
  autoWhiteBalanceMode: 0,
  brightness: 0,
  channel: 1,
  contrast: 1.0,
  exposureTime: 0,
  gain: 0,
  gps: true,
  manualFocusDistance: -1,
  maxSleepTime: 3600,
  performUpdate: false,
  rotate: 0,
  saturation: 1.0,
  setupMode: false,
  sharpness: 1.0,
  triggerTwoStep: false,
  updateCheckFrom: "21:00",
  updateCheckTo: "23:00",
};

const DEFAULT_GALLERY_DEVICE_SETTINGS: GalleryDeviceV2Settings = {
  autoExposure: false,
  autoFocus: false,
  autoWhiteBalance: true,
  autoWhiteBalanceMode: 0,
  brightness: 0,
  contrast: 1.0,
  exposureTime: 0,
  gain: 0,
  manualFocusDistance: -1,
  rotate: 0,
  saturation: 1.0,
  setupMode: false,
  sharpness: 1.0,
  triggerTwoStep: false,
};

const DEFAULT_DEVICE_INTERVAL_SETTINGS: DeviceV2IntervalSettings = {
  Monday: _.cloneDeep(DEFAULT_INTERVAL),
  Tuesday: _.cloneDeep(DEFAULT_INTERVAL),
  Wednesday: _.cloneDeep(DEFAULT_INTERVAL),
  Thursday: _.cloneDeep(DEFAULT_INTERVAL),
  Friday: _.cloneDeep(DEFAULT_INTERVAL),
  Saturday: _.cloneDeep(DEFAULT_INTERVAL),
  Sunday: _.cloneDeep(DEFAULT_INTERVAL),
};

const DEFAULT_DEVICE_INFO: DeviceV2Info = {
  id: "",
  deviceId: null,
  extraInfo: "",
};

const DEFAULT_GALLERY: GalleryV2 = {
  id: null,
  active: true,
  address: "",
  archived: false,
  assignedDevice: null,
  endDate: "",
  externalHostDirectory: "",
  galleryImageHost: "0",
  galleryName: "",
  jobSite: null,
  latestImage: "",
  maxSleepTime: 3600,
  pinnedLatitude: "",
  pinnedLongitude: "",
  startDate: "",
  useProxyApi: false,
  visible: true,
};

const DEFAULT_JOB_SITE: JobSite = {
  id: null,
  archived: false,
  associatedClients: [],
  email: "",
  gpsLocation: "",
  name: "",
  phone: "",
  physicalAddress: "",
  galleries: [],
};

const DEFAULT_CLIENT: Client = {
  id: null,
  address: "",
  email: "",
  name: "",
  phone: "",
  sites: [],
};

const DEFAULT_VERSION: Version = {
  id: "",
  channel: 1,
  epochTime: 0,
  fullDownloadUrl: "",
  fullMd5: "",
  fullSize: 0,
  linkedBuildId: "",
  partialDownloadUrl: "",
  partialMd5: "",
  partialSize: 0,
  storageLocation: "",
  version: "",
};

const DEFAULT_ADMIN_EVENT: AdminEvent = {
  id: "",
  deviceId: null,
  eventDetails: "",
  eventTime: 0,
  eventTitle: "",
  userId: null,
  username: "",
};

const DEFAULT_PHOTO_SENTINEL_IMAGE: PhotoSentielImage = {
  datetime_taken_local: "",
  hidden: false,
  original_url: "",
  photo_id: 0,
  preview_url: "",
  sha256: "",
  thumb_url: "",
  watermark_url: "",
};

const getDefaultData = <T>(data: T) => {
  return _.cloneDeep(data);
};

export const getDefaultUser = (): User => getDefaultData<User>(DEFAULT_USER);
export const getDefaultUserRole = (): UserRole =>
  getDefaultData<UserRole>(DEFAULT_USER_ROLE);
export const getDefaultImageExtras = (): ImageExtras =>
  getDefaultData<ImageExtras>(DEFAULT_IMAGE_EXTRAS);
export const getDefaultComment = (): Comment =>
  getDefaultData<Comment>(DEFAULT_COMMENT);
export const getDefaultInterval = (): IntervalV2 =>
  getDefaultData<IntervalV2>(DEFAULT_INTERVAL);
export const getDefaultDevice = (): DeviceV2 =>
  getDefaultData<DeviceV2>(DEFAULT_DEVICE);
export const getDefaultDeviceDetails = (): DeviceV2Details =>
  getDefaultData<DeviceV2Details>(DEFAULT_DEVICE_DETAILS);
export const getDefaultDeviceSettings = (): DeviceV2Settings =>
  getDefaultData<DeviceV2Settings>(DEFAULT_DEVICE_SETTINGS);
export const getDefaultDeviceIntervalSettings = (): DeviceV2IntervalSettings =>
  getDefaultData<DeviceV2IntervalSettings>(DEFAULT_DEVICE_INTERVAL_SETTINGS);
export const getDefaultDeviceInfo = (): DeviceV2Info =>
  getDefaultData<DeviceV2Info>(DEFAULT_DEVICE_INFO);
export const getDefaultGallery = (): GalleryV2 =>
  getDefaultData<GalleryV2>(DEFAULT_GALLERY);
export const getDefaultGalleryDeviceSettings = (): GalleryDeviceV2Settings =>
  getDefaultData<GalleryDeviceV2Settings>(DEFAULT_GALLERY_DEVICE_SETTINGS);
export const getDefaultClient = (): Client =>
  getDefaultData<Client>(DEFAULT_CLIENT);
export const getDefaultJobSite = (): JobSite =>
  getDefaultData<JobSite>(DEFAULT_JOB_SITE);
export const getDefaultVersion = (): Version =>
  getDefaultData<Version>(DEFAULT_VERSION);
export const getDefaultAdminEvent = (): AdminEvent =>
  getDefaultData<AdminEvent>(DEFAULT_ADMIN_EVENT);
export const getDefaultPhotoSentinelImage = (): PhotoSentielImage =>
  getDefaultData<PhotoSentielImage>(DEFAULT_PHOTO_SENTINEL_IMAGE);
