import {
  useEffect,
  useState,
  ChangeEvent,
  ReactElement,
  useRef,
  useMemo,
} from "react";
import { useAtom, useSetAtom } from "jotai";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import {
  Box,
  Card,
  Checkbox,
  Chip,
  Grid,
  Typography,
  IconButton,
  Container,
  alpha,
  Button,
  TextField,
  Autocomplete,
  MenuItem,
  Pagination,
  PaginationItem,
  Menu,
  MenuList,
  useMediaQuery,
  Theme,
  TextFieldProps,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import {
  StarBorder,
  Star,
  Download,
  HideImage,
  HideImageOutlined,
} from "@mui/icons-material";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import DownloadIcon from "@mui/icons-material/Download";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import DeselectIcon from "@mui/icons-material/Deselect";

import LDSRingLoader from "components/Loaders/LDSRingLoader";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { TopToolBar, TopToolBarButton } from "./ImageViewerWindow";

import { getFirebaseController } from "database/FirebaseController";
import ZipDownloader from "database/ImageDownloader";
import ImageHandlerV2, {
  Image,
  ImageFilterOptions,
  Thumbnail,
} from "database/ImageHandlerV2";
import { DeviceV2, GalleryV2, UnixEpoch } from "database/DataTypes";

import {
  gridComponentCahcesState,
  selectedImageState,
} from "states/imageViewer";

import _ from "lodash";

import moment, { Moment } from "moment";

import useAccess from "hooks/useAccess";
import useImageViewerTracker from "hooks/eventTracker/useImageViewerTracker";
import { createSelectOption } from "utils/input";
import { getContrastShade } from "theme/reliveItTheme";

export interface FilterInputs {
  id: string;
  where: string;
  condition: string;
  values: (string | boolean)[];
  isMulti: boolean;
}
interface SelectOption {
  value: string | boolean;
  label: string | boolean;
}

const DATE_RANGE_OPTIONS = [
  createSelectOption("Latest Day"),
  createSelectOption("Past Week"),
  createSelectOption("Past Month"),
  createSelectOption("Custom Month"),
  createSelectOption("All Time Tagged"),
  createSelectOption("All Time Favorited"),
  createSelectOption("All Time Commented"),
  createSelectOption("All Time Hidden", undefined, ["3"]),
  // createSelectOption("Past Year"),
  // createSelectOption("Custom Range"),
];

const WHERE_OPTIONS = [
  createSelectOption("Tags"),
  createSelectOption("Favorited"),
  createSelectOption("Commented"),
  createSelectOption("Hidden"),
];

const OPERATOR_OPTIONS = [
  createSelectOption("Is"),
  createSelectOption("Is Not"),
];

const GALLERY_BATCH_SIZE_OPTIONS = [25, 50, 75, 100, 200];

const ThumbnailCard = ({
  thumbnail,
  thumbnailIndex,
  imageHandler,
  handleImageClick,
  handleImageSelect,
  handleImageFavorite,
  handleImageHidden,
  getThumbnail,
  imagePlaceholder,
  selectedImageIdLookup,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [thumbnailSrc, setThumbnailSrc] = useState<string>("");

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    if (imageHandler) {
      await imageHandler.getImageExtras(thumbnail);

      getThumbnail(thumbnail).then((thumbnail) => {
        setThumbnailSrc(thumbnail.thumbnail);
        setIsLoading(false);

        setTags(imageHandler?.getImageTags(thumbnail) || []);
        setIsFavorited(imageHandler?.getIsImageFavourited(thumbnail));
        setIsCommented(imageHandler?.getIsImageCommented(thumbnail));
        setIsHidden(imageHandler?.getIsImageHidden(thumbnail));
      });
    }
  };

  useEffect(() => {
    if (imageHandler && thumbnail) {
      setIsHidden(imageHandler.getIsImageHidden(thumbnail));
    }
  }, [selectedImageIdLookup]);

  const [isFavorited, setIsFavorited] = useState(false);
  const [isCommented, setIsCommented] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [tags, setTags] = useState([]);

  const imageId = imageHandler?.getThumbnailImageId(thumbnail);

  const isSelected = !!selectedImageIdLookup[imageId];

  // const isFavorited = imageHandler?.getIsImageFavourited(thumbnail);
  // const isCommented = imageHandler?.getIsImageCommented(thumbnail);
  // const isHidden = imageHandler?.getIsImageHidden(thumbnail);

  const Loader = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <LDSRingLoader />
      </Box>
    );
  };

  const ThumbnailPlaceHolder = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          visibility:
            isLoading || !thumbnailSrc || !isLoaded ? "visible" : "hidden",
        }}
        onClick={() => {
          if (!isHidden) {
            handleImageClick(thumbnail);
          }
        }}
      >
        <Box
          component={"img"}
          crossOrigin="anonymous"
          width={"100%"}
          src={imagePlaceholder}
          sx={{
            display: "block",
            width: "100%",
            objectFit: "contain",
            visibility: "hidden",
          }}
        />

        <Loader />
      </Box>
    );
  };

  const imageTimestamp = useMemo(
    () => imageHandler?.getImageTime(thumbnail.image),
    [imageHandler, thumbnail.image],
  );

  const { isAdmin } = useAccess();

  const isAllowHide = isAdmin && imageHandler.isImageHidable(imageId);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{
          backgroundColor: ({ palette }) =>
            isSelected
              ? getContrastShade(palette.tertiary, "dark")
              : palette.tertiary.main,
          color: ({ palette }) => palette.onTertiary.main,

          width: "100",
          height: "100%",
          borderRadius: 1,
          transition: ({ transitions }) =>
            transitions.create("all", {
              duration: transitions.duration.shorter,
            }),
          boxShadow: 1,
          p: 1,
          cursor: isHidden ? "auto" : "pointer",
          opacity: isHidden ? 0.8 : 1,
          userSelect: isHidden ? "none" : "auto",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          ":hover": isHidden
            ? {}
            : {
                transform: "scale(1.01)",
                boxShadow: 2,
                backgroundColor: ({ palette }) =>
                  isSelected
                    ? getContrastShade(palette.tertiary, "dark")
                    : alpha(getContrastShade(palette.tertiary, "dark"), 0.8),
              },
        }}
      >
        <Box
          sx={{
            cursor: "auto",
            backgroundColor: ({ palette }) => palette.surface.main,
            color: ({ palette }) => palette.onSurface.main,
            borderRadius: 1,
            py: 1,
            px: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  lineHeight: 1,
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {moment(imageTimestamp).format("ddd, MMM DD, YYYY")}
              </Typography>

              <Typography
                sx={{
                  lineHeight: 1,
                  fontSize: 14,
                  mt: 0.5,
                }}
              >
                {moment(imageTimestamp).format("HH:mm A")}
              </Typography>
            </Box>

            <Grid container gap={0.5} width={"max-content"}>
              {isCommented && (
                <Grid
                  item
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <ChatIcon
                    sx={({ palette }) => ({
                      fontSize: 18,
                      // just to make it looks nice visually
                      mr: 0.25,
                      color: getContrastShade(palette.secondary, "light"),
                    })}
                  />
                </Grid>
              )}

              {isAllowHide && (
                <Grid
                  item
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Tooltip title="Hide">
                    <Checkbox
                      disableRipple
                      color="secondary"
                      name="isFavorited"
                      checked={isHidden}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        setIsHidden(e.target.checked);

                        // do not wait
                        handleImageHidden(e, thumbnail, thumbnailIndex).then(
                          (isHidden) => {
                            setIsHidden(isHidden);
                          },
                        );
                      }}
                      // disabled={isLoading || isDownloading}
                      icon={<HideImageOutlined />}
                      checkedIcon={<HideImage />}
                      sx={({ palette }) => ({
                        p: 0,
                        m: 0,
                        color: palette.text.main,
                        "&.Mui-checked": {
                          color: palette.warning.dark,
                        },
                        "& .MuiSvgIcon-root": { fontSize: 18 },
                      })}
                    />
                  </Tooltip>
                </Grid>
              )}

              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Tooltip title="Favorite">
                  <Checkbox
                    disableRipple
                    color="secondary"
                    name="isFavorited"
                    checked={isFavorited}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={async (e) => {
                      setIsFavorited(e.target.checked);

                      handleImageFavorite(e, thumbnail, thumbnailIndex).then(
                        (isFavorited) => {
                          setIsFavorited(isFavorited);
                        },
                      );
                    }}
                    // disabled={isLoading || isDownloading}
                    icon={<StarBorder />}
                    checkedIcon={<Star />}
                    sx={({ palette }) => ({
                      p: 0,
                      m: 0,
                      color: palette.onSurface.main,
                      "&.Mui-checked": {
                        color: palette.primary.main,
                      },
                      "& .MuiSvgIcon-root": { fontSize: 19 },
                    })}
                  />
                </Tooltip>
              </Grid>

              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Checkbox
                  disableRipple
                  name="isSelected"
                  checked={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    handleImageSelect(e, thumbnail);
                  }}
                  // disabled={isLoading || isDownloading}
                  sx={({ palette }) => ({
                    p: 0,
                    m: 0,
                    color: palette.onSurface.main,
                    "&.Mui-checked": {
                      color: palette.primary.main,
                    },
                    "& .MuiSvgIcon-root": { fontSize: 18 },
                  })}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{ mt: 1, position: "relative", height: "100%", minHeight: 200 }}
        >
          <ThumbnailPlaceHolder />
          <Box
            component={"img"}
            crossOrigin="anonymous"
            width={"100%"}
            onLoad={() => setIsLoaded(true)}
            src={thumbnailSrc}
            sx={{
              display: "block",
              width: "100%",
              objectFit: "contain",
            }}
            onClick={() => {
              if (!isHidden) {
                handleImageClick(thumbnail);
              }
            }}
          />

          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              overflow: "auto",

              width: "100%",

              p: 1,
              pb: 2,
              opacity: 0.4,
              transition: "all .3s ease",

              "::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "6px",
              },

              "&:hover": {
                opacity: 1,
              },
            }}
          >
            <Grid container spacing={1} wrap="nowrap">
              {tags.map((tag) => {
                return (
                  <Grid item key={tag}>
                    <Chip
                      label={tag}
                      color={"secondary"}
                      size="small"
                      sx={{
                        borderRadius: 0.5,
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

const FilterInputContainer = ({
  filterInput,
  filterInputIndex,
  handleFilterInputChange,
  handleFilterInputRemove,
  WHERE_OPTIONS,
  OPERATOR_OPTIONS,
  getValueOptions,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (filterInput.isMulti && filterInput.values !== selectedOptions) {
      setSelectedOptions(filterInput.values.map((v) => createSelectOption(v)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput.isMulti, filterInput.values]);

  const mediumScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  );

  const baseInputProps: TextFieldProps = {
    size: "small",
    fullWidth: true,
    variant: "outlined",
    sx: {
      ".MuiInputBase-root": {
        fontSize: {
          xs: 12,
          md: 16,
        },
      },
    },
  };

  return (
    <Grid
      container
      columnSpacing={2}
      key={filterInputIndex}
      id={`filter-input-container-${filterInputIndex}`}
      sx={{
        flexWrap: "nowrap",
      }}
    >
      <Grid item sx={{ flex: 1, minWidth: 150, maxWidth: 250 }}>
        <InputFieldWrapper label={"Type:"}>
          <TextField
            select
            onChange={(e) => {
              handleFilterInputChange(
                "where",
                createSelectOption(e.target.value),
                filterInputIndex,
              );
            }}
            value={filterInput.where}
            {...baseInputProps}
          >
            {WHERE_OPTIONS.map((option, optionIndex) => {
              return (
                <MenuItem
                  dense={true}
                  key={optionIndex}
                  value={option.value as string}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </TextField>
        </InputFieldWrapper>
      </Grid>

      {filterInput.where === "Tags" && (
        <Grid item sx={{ flex: 1, minWidth: 150, maxWidth: 250 }}>
          <InputFieldWrapper label={"Tags:"}>
            <Autocomplete
              disableCloseOnSelect
              multiple
              fullWidth
              renderOption={(props, option: any, { selected }) => (
                <li {...props} style={{ padding: 0 }}>
                  <Checkbox
                    sx={{}}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={filterInput.values.some((v) => v === option.value)}
                  />
                  <Typography fontSize={14}>{option.label}</Typography>
                </li>
              )}
              renderTags={(tagValue, getTagProps) => (
                <Chip
                  label={`+ ${tagValue.length}`}
                  color={"secondary"}
                  size="small"
                  sx={{
                    borderRadius: 0.5,
                  }}
                />
              )}
              slotProps={{
                paper: {
                  sx: {
                    fontSize: {
                      xs: 12,
                      md: 16,
                    },
                  },
                },
              }}
              sx={{
                ".MuiAutocomplete-input": {
                  fontSize: {
                    xs: 12,
                    md: 16,
                  },
                },

                control: () => ({
                  borderRadius: 1,
                }),
              }}
              value={filterInput.values.map((v) => createSelectOption(v))}
              size="small"
              options={getValueOptions(filterInput.where)}
              onChange={(e, v, c, d) => {
                handleFilterInputChange("values", v, filterInputIndex);
              }}
              renderInput={(params: any) => (
                <TextField {...params} multiline={false} />
              )}
            />
          </InputFieldWrapper>
        </Grid>
      )}

      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ml: -1,
        }}
      >
        <InputFieldWrapper label={""}>
          <IconButton
            size="small"
            onClick={() => handleFilterInputRemove(filterInputIndex)}
          >
            <DeleteIcon color="error" fontSize="small" />
          </IconButton>
        </InputFieldWrapper>
      </Grid>
    </Grid>
  );
};

const InputFieldWrapper = (props: {
  label: string;
  children: ReactElement;
}) => {
  return (
    <Box>
      <Grid container spacing={1} direction={"column"}>
        <Grid item height={"max-content"}>
          <Typography
            sx={({ palette }) => ({
              pl: 0,
              pb: 0,
              lineHeight: 1,
              fontSize: 12,
              fontWeight: "bold",
              color: palette.onTertiary.main,
              visibility: props.label ? "visible" : "hidden",
            })}
          >
            {props.label || "-"}
          </Typography>
        </Grid>

        <Grid item>{props.children}</Grid>
      </Grid>
    </Box>
  );
};

const ImageViewerGridWindow = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentDevice, setCurrentDevice] = useState<DeviceV2 | GalleryV2>();
  const [imageHandler, setImageHandler] = useState<ImageHandlerV2 | null>(null);
  const [thumbnails, setThumbnails] = useState<Thumbnail<Image>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isTogglingHidden, setIsTogglingHidden] = useState<boolean>(false);
  const [selectedImageIdLookup, setSelectedImageIdLookup] = useState<{
    // value is always gonna be true, use hash instead of array for better performance
    [key: string | number]: true;
  }>({});
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [filterInputs, setFilterInputs] = useState<FilterInputs[]>([]);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [imagePlaceholder, setImagePlaceholder] = useState<string>("");
  const [dateRangeOption, setDateRangeOption] = useState<string>(
    DATE_RANGE_OPTIONS[0].value,
  );
  const [currentDateRange, setCurrentDateRange] = useState<UnixEpoch[]>([]);

  const [customDateRange, setCustomDateRange] = useState<UnixEpoch[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(10);

  const [currentBatchSize, setCurrentBatchSize] = useState<number>(
    GALLERY_BATCH_SIZE_OPTIONS[0],
  );

  const [batchSizeOptionsEl, setBatchSizeOptionsEl] =
    useState<null | HTMLElement>(null);

  const setSelectedImage = useSetAtom(selectedImageState);

  const isDevice = !!location.pathname.match("/devices/");

  const {
    isSiteAccessable,
    isAdmin,
    isAccessable,
    isGalleryRestricted,
    userAccessLevel,
  } = useAccess();

  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const isHidingSelected = useMemo(() => {
    const ids = Object.keys(selectedImageIdLookup).filter((id) => {
      return imageHandler?.isImageHidable(id);
    });

    return (
      ids.length === 0 ||
      ids.some((image) => {
        return !imageHandler?.hiddenImageIds.includes(image);
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImageIdLookup, imageHandler]);

  const mediumScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  );

  const buttons: TopToolBarButton[] = [
    ...(mediumScreenAndUp
      ? []
      : ([
          {
            name: "select all",
            description: isAllSelected ? `Deselect All` : `Select All`,
            icon: isAllSelected ? <DeselectIcon /> : <SelectAllIcon />,
            onClick: () => {
              handleSelectAll();
            },
            isDisabled: isLoading,
            divider: true,
          },
          {
            name: "hide",
            description: `${isHidingSelected ? "Hide" : "Unhide"} Images`,
            icon: <HideImage />,
            onClick: () => handleImagesHide(),
            isDisabled: isLoading || isTogglingHidden,
            isBlocked: !isAccessable(["3"]),
            isActive: isTogglingHidden,
          },
          {
            name: "download",
            description: `Download (${
              Object.keys(selectedImageIdLookup).length
            })`,
            icon: <Download />,
            onClick: (e) => setDownloadMenuAnchorEl(e.currentTarget),
            divider: true,
            isDisabled: isLoading || isDownloading,
            isActive: isDownloading,
          },
        ] as TopToolBarButton[])),
    {
      name: "back",
      description: "Back to Image Viewer",
      icon: <ArrowBackIcon />,

      onClick: () => {
        const toPath = location.pathname.split("/").slice(0, -1).join("/");

        navigate(toPath);
      },
    },
  ];

  const [componentCahces, setComponentCahces] = useAtom(
    gridComponentCahcesState,
  );

  const compCachesRef = useRef<any>(null);
  const firstRender = useRef(true);
  const skipEffects = useRef({
    imageHandler: false,
    filterInputs: false,
  });

  const { trackAction } = useImageViewerTracker(imageHandler, "grid", true);

  useEffect(() => {
    compCachesRef.current = {
      imageHandler,
      thumbnails,
      selectedImageIdLookup,
      filterInputs,
      isFilterOpened,
      imagePlaceholder,
      dateRangeOption,
      currentDateRange,
      customDateRange,
      currentPage,
      totalPage,
      totalCount,
      currentBatchSize,
    };
  });

  useEffect(() => {
    setIsLoading(true);

    const caches = componentCahces[params.id as string];

    if (caches && caches.imageHandler) {
      setCurrentDevice(caches.imageHandler.currentObject);
      setImageHandler(caches.imageHandler);
      setThumbnails(caches.thumbnails);
      setSelectedImageIdLookup(caches.selectedImageIdLookup);
      setFilterInputs(caches.filterInputs);
      setIsFilterOpened(caches.isFilterOpened);
      setImagePlaceholder(caches.imagePlaceholder);
      setDateRangeOption(caches.dateRangeOption);
      setCurrentDateRange(caches.currentDateRange);
      setCustomDateRange(caches.customDateRange);
      setCurrentPage(caches.currentPage);
      setTotalPage(caches.totalPage);
      setTotalCount(caches.totalCount);
      setCurrentBatchSize(caches.currentBatchSize);
      setIsLoading(false);

      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = caches.scrollTop || 0;
        }
      }, 1);

      skipEffects.current.filterInputs = true;
      skipEffects.current.imageHandler = true;

      // if (isAdmin) {
      //   caches.imageHandler.handleHiddenImageCachesInit();
      // }
    } else {
      setImageHandler(null);
      setThumbnails([]);
      setSelectedImageIdLookup({});
      setIsFilterOpened(false);
      setFilterInputs([]);
      setDateRangeOption(DATE_RANGE_OPTIONS[0].value);

      getDevice();
    }

    return () => {
      setComponentCahces((caches) => {
        return {
          ...caches,
          [params.id as string]: _.cloneDeep(compCachesRef.current),
        };
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (!firstRender.current && imageHandler) {
      if (skipEffects.current.imageHandler) {
        skipEffects.current.imageHandler = false;

        return;
      }

      imageHandler
        .getLatestImages()
        .then(async () => {
          await filter(
            moment.unix(imageHandler.fullDateRange[1]).startOf("day").unix(),
            moment.unix(imageHandler.fullDateRange[1]).endOf("day").unix(),
          );

          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);

          console.error(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageHandler]);

  useEffect(() => {
    if (!firstRender.current && imageHandler) {
      if (skipEffects.current.filterInputs) {
        skipEffects.current.filterInputs = false;

        return;
      }

      filter(imageHandler.filterStartDate, imageHandler.filterEndDate, {
        filtersUpdated: true,
        filterImageIds: imageHandler.filterImageIds,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInputs]);

  // this must be place on last
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const getDevice = async () => {
    setIsLoading(true);

    const getData = async () =>
      isDevice
        ? await getFirebaseController().Device.getDevice(params.id)
        : await getFirebaseController().Gallery.getGallery(params.id);

    await getData()
      .then(async (currentItem) => {
        if (!currentItem) {
          throw new Error(`${isDevice ? "Divice" : "Gallery"} not found.`);
        }

        const isGallery = !isDevice;
        const isDeviceNotAccessible = isDevice && !isAdmin;
        const isGalleryNotAccessible =
          isGallery &&
          !isAdmin &&
          (!isSiteAccessable((currentItem as GalleryV2).jobSite) ||
            !(currentItem as GalleryV2).visible ||
            isGalleryRestricted(currentItem.id));

        if (isDeviceNotAccessible || isGalleryNotAccessible) {
          navigate("/");
          return;
        }

        setCurrentDevice(currentItem);

        setImageHandler(new ImageHandlerV2(currentItem, isDevice));
      })
      .catch((err) => {
        console.error(err);
        navigate("../");
      });
  };

  const getValidFilterInputs = () => {
    return filterInputs.filter((input) => {
      return input.where && input.where && input.values.length > 0;
    });
  };

  const handlePageChanged = (newPage, batchSize = currentBatchSize) => {
    if (imageHandler) {
      setThumbnails(
        imageHandler.thumbnails.slice(
          (newPage - 1) * batchSize,
          newPage * batchSize,
        ),
      );

      setCurrentPage(newPage);

      setTotalCount(imageHandler.thumbnails.length);

      setTotalPage(Math.ceil(imageHandler.thumbnails.length / batchSize));

      setSearchParams((searchParams) => {
        searchParams.set("page", newPage);
        searchParams.set("batchSize", batchSize.toString());
        return searchParams;
      });

      containerRef.current?.scrollTo({
        top: 0,
      });
    }
  };

  const handleBatchSizeChange = (size) => {
    setCurrentBatchSize(size);
    setBatchSizeOptionsEl(null);
    handlePageChanged(1, size);
  };

  const filter = async (
    fromDate: UnixEpoch,
    toDate: UnixEpoch,
    {
      filtersUpdated = false,
      filterImageIds = null,
      hiddenImageIds,
    }: ImageFilterOptions = {},
  ) => {
    if (imageHandler) {
      setIsLoading(true);

      await imageHandler
        .filter(fromDate, toDate, {
          filterInputs: getValidFilterInputs(),
          filtersUpdated,
          filterImageIds,
          hiddenImageIds: isAdmin ? [] : undefined,
        })
        .then(() => {
          handlePageChanged(1);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  };

  const getThumbnail = async (thumbnail) => {
    if (imageHandler) {
      const result = await imageHandler.findThumbnail(thumbnail);

      if (result.event) {
        await result.event;
      }

      return thumbnail;
    }
  };

  const handleImageFavorite = async (
    e: ChangeEvent<HTMLInputElement>,
    thumbnail: Thumbnail<Image>,
    thumbnailIndex: number,
  ) => {
    const { checked } = e.target;

    if (checked) {
      trackAction("hide");
    }

    return await imageHandler
      ?.updateImageFavorited(thumbnail, checked)
      .then((imageExtra) => {
        // force update

        if (imageExtra) {
          return imageExtra.favorited;
        } else {
          return !checked;
        }
      })
      .catch((err) => {
        console.error(err);

        return !checked;
      });
  };

  const handleImageHidden = async (
    e: ChangeEvent<HTMLInputElement>,
    thumbnail: Thumbnail<Image>,
    thumbnailIndex: number,
  ) => {
    const { checked } = e.target;

    if (checked) {
      trackAction("favorite");
    }

    return await imageHandler
      ?.updateImageHiddenStatus(thumbnail, checked)
      .then((isHidden) => {
        return isHidden;
      })
      .catch((err) => {
        console.error(err);

        return !checked;
      });
  };

  const handleImageSelect = (
    e: ChangeEvent<HTMLInputElement> | null,
    thumbnail: Thumbnail<Image>,
    isChecked: boolean,
  ) => {
    setIsAllSelected(false);

    const checked = isChecked || e?.target?.checked;

    const newSelectedImageIdLookup = { ...selectedImageIdLookup };

    if (checked) {
      setSelectedImageIdLookup({
        ...newSelectedImageIdLookup,
        [imageHandler!.getThumbnailImageId(thumbnail)]: checked,
      });
    } else {
      delete newSelectedImageIdLookup[
        imageHandler!.getThumbnailImageId(thumbnail)
      ];

      setSelectedImageIdLookup(newSelectedImageIdLookup);
    }

    setIsAllSelected(false);
  };

  const handleImageClick = (thumbnail: Thumbnail<Image>) => {
    navigate(`../${params.id}/image-viewer`);
    setSelectedImage(thumbnail.image);
  };

  const handleImagesDownload = async (withTimeStamp = false) => {
    const selectedImages: Image[] = [];

    trackAction(
      withTimeStamp ? "download with timestamp" : "download original",
    );

    thumbnails.forEach((thumbnail) => {
      if (selectedImageIdLookup[imageHandler!.getThumbnailImageId(thumbnail)]) {
        selectedImages.push(thumbnail.image);
      }
    });

    if (selectedImages.length > 0 && imageHandler) {
      setIsDownloading(true);

      await ZipDownloader.downloader(
        selectedImages,
        imageHandler.hostUsed,
        withTimeStamp,
      ).then(() => {
        setIsDownloading(false);
      });
    }
  };

  const handleImagesHide = async () => {
    setIsTogglingHidden(true);

    const selectedThumbnails: Thumbnail<Image>[] = [];

    thumbnails.forEach((thumbnail) => {
      const imageId = imageHandler!.getThumbnailImageId(thumbnail);
      const isHidable = imageHandler!.isImageHidable(imageId);

      if (isHidable && selectedImageIdLookup[imageId]) {
        selectedThumbnails.push(thumbnail);
      }
    });

    const promises: Promise<any>[] = [];

    if (selectedThumbnails.length > 0 && imageHandler) {
      selectedThumbnails.forEach((thumbnail) => {
        promises.push(
          imageHandler.updateImageHiddenStatus(thumbnail, isHidingSelected),
        );
      });
    }

    await Promise.all(promises)
      .then((result) => {
        setSelectedImageIdLookup({});
        setIsAllSelected(false);
        setIsTogglingHidden(false);
      })
      .catch((err) => {
        console.error(err);
        setIsTogglingHidden(false);
      });
  };

  const handleSelectAll = () => {
    let newSelectedImageIdLookup = { ...selectedImageIdLookup };

    if (isAllSelected) {
      newSelectedImageIdLookup = {};
    } else {
      thumbnails.forEach((thumbnail: any) => {
        newSelectedImageIdLookup[thumbnail.image.id] = true;
      });

      trackAction("select all");
    }

    setIsAllSelected(!isAllSelected);
    setSelectedImageIdLookup(newSelectedImageIdLookup);
  };

  const handleDateInputChange = (
    date: Moment,
    name: "end" | "start" | "month",
  ) => {
    let start = customDateRange[0];
    let end = customDateRange[1];

    if (name === "start") {
      start = date.startOf("days").unix();

      if (start > end) {
        end = date.endOf("days").unix();
      }
    } else if (name === "end") {
      end = date.endOf("days").unix();

      if (end < start) {
        start = date.startOf("days").unix();
      }
    } else if (name === "month") {
      start = date.startOf("month").unix();
      end = date.endOf("month").unix();
    }

    setCustomDateRange([start, end]);

    filter(start, end);
  };

  const handleDateRangeInputChange = async (e) => {
    if (!imageHandler) {
      return;
    }

    const latestDate = imageHandler.fullDateRange[1];

    let dateRange = [latestDate, latestDate];
    let filterImageIds: (string | number)[] | null = null;

    setDateRangeOption(e.target.value);

    setSearchParams((searchParams) => {
      searchParams.set("date", e.target.value);

      return searchParams;
    });

    try {
      switch (e.target.value) {
        case "Past Week":
          dateRange = [
            moment.unix(latestDate).subtract(1, "weeks").unix(),
            latestDate,
          ];

          trackAction("filter past week");

          break;
        case "Past Month":
          dateRange = [
            moment.unix(latestDate).subtract(1, "months").unix(),
            latestDate,
          ];
          trackAction("filter past month");

          break;
        case "Past Year":
          dateRange = [
            moment.unix(latestDate).subtract(1, "years").unix(),
            latestDate,
          ];

          break;
        case "Custom Month":
          // auto select latest month first

          dateRange = [
            moment.unix(latestDate).startOf("months").unix(),
            moment.unix(latestDate).endOf("months").unix(),
          ];
          trackAction("filter custom month");

          setCustomDateRange(dateRange);
          break;
        case "Custom Range":
          setCustomDateRange(dateRange);
          break;
        case "All Time Favorited": {
          filterImageIds = await imageHandler.getFavoritedImageIds();

          dateRange = imageHandler.fullDateRange;

          trackAction("filter all time favorited");

          break;
        }

        case "All Time Commented": {
          filterImageIds = await imageHandler.getCommentedImageIds();

          dateRange = imageHandler.fullDateRange;

          trackAction("filter all time commented");

          break;
        }

        case "All Time Tagged": {
          filterImageIds = await imageHandler.getTaggedImageIds();

          dateRange = imageHandler.fullDateRange;

          trackAction("filter all time tagged");

          break;
        }

        case "All Time Hidden": {
          filterImageIds = await imageHandler.getHiddenImageIds();

          dateRange = imageHandler.fullDateRange;

          trackAction("filter all time hidden");

          break;
        }

        case "Latest":
        default:
          dateRange = [
            moment.unix(latestDate).startOf("day").unix(),
            moment.unix(latestDate).endOf("day").unix(),
          ];

          break;
      }

      setCurrentDateRange(dateRange);

      await filter(dateRange[0], dateRange[1], {
        filtersUpdated: false,
        filterImageIds,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterInputChange = (
    name: "where" | "condition" | "values",
    options: SelectOption[] | SelectOption,
    filterInputIndex,
  ) => {
    const newFilterInputs: FilterInputs[] = _.cloneDeep(filterInputs);

    switch (name) {
      case "values": {
        const newValues = _.flatten([options]).map((o) => o.value);

        const counts = _.countBy(newValues);

        newFilterInputs[filterInputIndex]["values"] = _.filter(
          newValues,
          (item) => counts[item as string] === 1,
        );

        break;
      }
      case "where":
      case "condition":
      default: {
        const value = (options as SelectOption).value as string;

        newFilterInputs[filterInputIndex][name] = value;

        newFilterInputs[filterInputIndex].isMulti = value === "Tags";
        newFilterInputs[filterInputIndex]["values"] =
          value === "Tags" ? [] : [true];
        break;
      }
    }

    const currentFilterInput = newFilterInputs[filterInputIndex];

    switch (currentFilterInput.where) {
      case "Tags":
        trackAction("filter tags");

        break;
      case "Favorited":
        trackAction("filter favorited");

        break;
      case "Commented":
        trackAction("filter commented");

        break;
      default:
    }

    setFilterInputs(newFilterInputs);
  };

  const handleFilterOpenToggle = () => {
    const newIsFilterOpened = !isFilterOpened;

    if (newIsFilterOpened && filterInputs.length < 1) {
      setFilterInputs([
        {
          id: moment().unix().toString(),
          where: "Tags",
          condition: "Is",
          values: [],
          isMulti: true,
        },
      ]);
    }

    setIsFilterOpened(newIsFilterOpened);
  };

  const handleFilterInputAdd = () => {
    skipEffects.current.filterInputs = true;

    setFilterInputs((prev) => {
      return [
        ...prev,
        {
          id: moment().unix().toString(),
          where: "",
          condition: "Is",
          values: [],
          isMulti: true,
        },
      ];
    });
  };

  const handleFilterInputRemove = (filterInputIndex) => {
    if (!filterInputs[filterInputIndex].where) {
      skipEffects.current.filterInputs = true;
    }

    setFilterInputs((prev) => {
      const newFilterInputs = [...prev];
      newFilterInputs.splice(filterInputIndex, 1);

      return newFilterInputs;
    });
  };

  const getValueOptions = (where: string) => {
    switch (where) {
      case "Tags":
        return [
          ...(imageHandler?.getAllImageTags() || []).map((t) =>
            createSelectOption(t),
          ),
        ];

      case "Commented":
      case "Favorited":
      case "Hidden":
        return [createSelectOption(true), createSelectOption(false)];
      default:
        return [];
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const breadcrumbs = useMemo(() => {
    if (!currentDevice || !isAdmin) {
      return [];
    }

    if (isDevice) {
      const device = currentDevice as DeviceV2;
      return [
        {
          label: "Devices",
          path: "/devices",
        },
        {
          label: device.friendlyName || device.deviceId || "",
          path: `/devices/${device.id}`,
        },
        {
          label: "Image Viewer",
          path: `/devices/${device.id}/image-viewer`,
        },
        {
          label: "List",
        },
      ];
    } else {
      const gallery = currentDevice as GalleryV2;

      return [
        {
          label: "Galleries",
          path: "/galleries",
        },
        {
          label: gallery.galleryName || "",
          path: `/galleries/${gallery.id}`,
        },
        {
          label: "Image Viewer",
          path: `/galleries/${gallery.id}/image-viewer`,
        },
        {
          label: "List",
        },
      ];
    }
  }, [isDevice, isAdmin, currentDevice]);

  const getDateRangeTextDisplay = useMemo(() => {
    if (["Past Week", "Past Month", "Custom Month"].includes(dateRangeOption)) {
      const dates = currentDateRange.map((date) => moment.unix(date));

      if (dates.length) {
        return `from ${dates[0].format("DD/MM/YY")} to ${dates[1].format(
          "DD/MM/YY",
        )}.`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }, [currentDateRange, dateRangeOption]);

  return (
    <Box
      onScroll={(e) => {
        compCachesRef.current.scrollTop = e.currentTarget.scrollTop;
      }}
      ref={containerRef}
      sx={({ palette }) => ({
        width: "100vw",
        height: "100vh",
        backgroundColor: palette.secondary.main,
        color: palette.onSecondary.main,
        position: "fixed",
        overflow: "auto",
        ".MuiIconButton-root": {
          color: alpha(palette.onSecondary.main, 0.8),
          transition: ({ transitions }) => transitions.create("all"),

          ":hover": {
            color: palette.onSecondary.main,
          },

          "&.Mui-disabled": {
            color: alpha(palette.onSecondary.main, 0.4),
          },
        },
      })}
    >
      <TopToolBar buttons={buttons} imageHandler={imageHandler} />

      <Menu
        anchorEl={downloadMenuAnchorEl}
        open={Boolean(downloadMenuAnchorEl)}
        onClose={() => setDownloadMenuAnchorEl(null)}
        sx={{
          ".MuiMenu-paper": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            boxShadow: 3,
          },
        }}
      >
        <MenuList disablePadding dense>
          {["Original Image", "With Timestamp"].map((text, textIndex) => {
            return (
              <MenuItem
                key={textIndex}
                dense
                onClick={() => {
                  handleImagesDownload(textIndex !== 0);
                  setDownloadMenuAnchorEl(null);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DownloadIcon sx={{ fontSize: "16px !important" }} />
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>{text}</Box>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>

      <Box
        sx={({ palette, customConfig }) => ({
          py: customConfig.imageViewerNavbarHeight,
          height: "100%",
          width: "100%",
        })}
      >
        <Container fixed maxWidth={"xl"} sx={{ p: 3, pt: 0 }}>
          <Breadcrumbs breadcrumbs={breadcrumbs} sx={{ py: 2 }} />

          <Box pb={2}>
            <Grid
              container
              alignItems={"stretch"}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Grid item display={"flex"} alignItems={"center"}>
                <TextField
                  size="small"
                  select
                  variant={"outlined"}
                  onChange={handleDateRangeInputChange}
                  value={dateRangeOption}
                  color="onBackground"
                  sx={{
                    width: 200,
                    ".MuiInputBase-root": {
                      backgroundColor: ({ palette }) =>
                        alpha(getContrastShade(palette.secondary), 0.2),
                      color: ({ palette }) => palette.onBackground.main,

                      fontSize: {
                        xs: 14,
                        md: 16,
                      },

                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: ({ palette }) =>
                          alpha(palette.onSecondary.main, 1),
                      },

                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: ({ palette }) => palette.primary.main,
                      },

                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: ({ palette }) =>
                          alpha(palette.onSecondary.main, 0.5),
                      },
                    },
                  }}
                >
                  {DATE_RANGE_OPTIONS.map((option, optionIndex) => {
                    if (
                      option.accessableLevel &&
                      userAccessLevel &&
                      !option.accessableLevel.includes(userAccessLevel)
                    ) {
                      return null;
                    }

                    return (
                      <MenuItem
                        dense
                        key={optionIndex}
                        value={option.value as string}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  color="onBackground"
                  onClick={handleFilterOpenToggle}
                  sx={{
                    textTransform: "none",
                    fontSize: {
                      xs: 12,
                      md: 14,
                    },
                    height: "100%",

                    mr: {
                      xs: 0,
                      md: 4,
                    },
                    color: ({ palette }) => palette.white.main,
                  }}
                  disabled={isLoading}
                  startIcon={
                    <>
                      {filterInputs.length > 0 ? (
                        <FilterListIcon
                          sx={({ palette }) => ({
                            color: palette.onBackground.main,
                          })}
                        />
                      ) : (
                        <FilterListOffIcon
                          sx={({ palette }) => ({
                            color: palette.onBackground.main,
                          })}
                        />
                      )}
                    </>
                  }
                >
                  Filter
                </Button>

                {mediumScreenAndUp && (
                  <>
                    <Button
                      variant="outlined"
                      color="onBackground"
                      onClick={handleSelectAll}
                      sx={{ mr: 1, textTransform: "none", height: "100%" }}
                      disabled={isLoading}
                    >
                      {isAllSelected ? `Deselect All` : `Select All`}
                    </Button>

                    {isAdmin && (
                      <Button
                        variant="outlined"
                        color="white"
                        onClick={(e) => handleImagesHide()}
                        sx={{ mr: 1, textTransform: "none", height: "100%" }}
                        disabled={isLoading || isTogglingHidden}
                        startIcon={
                          isTogglingHidden ? (
                            <CircularProgress size={12} />
                          ) : undefined
                        }
                      >
                        {` ${isHidingSelected ? "Hide" : "Unhide"} `}
                      </Button>
                    )}

                    <Button
                      variant="outlined"
                      color="onBackground"
                      onClick={(e) => setDownloadMenuAnchorEl(e.currentTarget)}
                      sx={{ textTransform: "none", height: "100%" }}
                      disabled={isLoading || isDownloading}
                      startIcon={
                        isDownloading ? (
                          <CircularProgress size={12} />
                        ) : undefined
                      }
                    >
                      {`Download (${
                        Object.keys(selectedImageIdLookup).length
                      })`}
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>

            {dateRangeOption === "Custom Month" && imageHandler && (
              <Grid item display={"flex"} alignItems={"center"} gap={1} mt={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={["year", "month"]}
                    minDate={moment
                      .unix(imageHandler!.fullDateRange[0])
                      .startOf("month")}
                    maxDate={moment
                      .unix(imageHandler!.fullDateRange[1])
                      .endOf("month")}
                    disabled={isLoading || isDownloading}
                    value={moment.unix(customDateRange[0])}
                    slotProps={{
                      popper: {
                        sx: {
                          ".MuiPickersMonth-monthButton.Mui-disabled, .MuiPickersYear-yearButton.Mui-disabled":
                            {
                              color: ({ palette }) =>
                                alpha(palette.secondary.main, 0.4),
                            },
                        },
                      },
                      textField: {
                        size: "small",
                        color: "onBackground",

                        sx: {
                          width: 200,
                          ".MuiInputBase-root": {
                            backgroundColor: ({ palette }) =>
                              alpha(getContrastShade(palette.secondary), 0.2),
                            color: ({ palette }) => palette.onBackground.main,
                            outline: ({ palette }) =>
                              `${palette.onSecondary.main} !important`,

                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 1),
                            },

                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                palette.primary.main,
                            },

                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 0.5),
                            },
                          },
                        },
                      },
                    }}
                    onAccept={(date) =>
                      handleDateInputChange(date as Moment, "month")
                    }
                  />
                </LocalizationProvider>
              </Grid>
            )}

            {isFilterOpened && (
              <Box pt={2}>
                <Box
                  id={"filter-container"}
                  sx={({ palette }) => ({
                    backgroundColor: palette.tertiary.main,
                    color: palette.onTertiary.main,
                    p: 2,
                    borderRadius: 1,
                  })}
                >
                  <Box
                    id={"filter-inputs-container"}
                    sx={{
                      overflow: "auto",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    {filterInputs.map((filterInput, filterInputIndex) => {
                      return (
                        <FilterInputContainer
                          key={filterInput.id}
                          filterInput={filterInput}
                          filterInputIndex={filterInputIndex}
                          handleFilterInputChange={handleFilterInputChange}
                          handleFilterInputRemove={handleFilterInputRemove}
                          WHERE_OPTIONS={WHERE_OPTIONS}
                          OPERATOR_OPTIONS={OPERATOR_OPTIONS}
                          getValueOptions={getValueOptions}
                        />
                      );
                    })}
                  </Box>

                  <Grid
                    container
                    alignItems={"center"}
                    sx={({ palette }) => ({
                      mt: 2,
                      width: "max-content",
                      color: palette.onTertiary.main,
                      fontSize: "14px !important",

                      opacity: 0.8,
                      cursor: "pointer",
                      transition: "all .2s ease",

                      ":hover": {
                        opacity: 1,
                      },
                    })}
                    onClick={handleFilterInputAdd}
                  >
                    <Grid item display={"flex"}>
                      <AddIcon
                        fontSize="small"
                        sx={({ palette }) => ({
                          fontSize: "14px !important",
                          color: palette.onTertiary.main,
                        })}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={({ palette }) => ({
                          fontSize: 12,
                          fontWeight: "bold",
                          color: palette.onTertiary.main,
                        })}
                        variant="body1"
                      >
                        Add Filter
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>

          {!isLoading && thumbnails.length > 0 && (
            <Typography
              fontSize={12}
              sx={{
                fontSize: 12,

                mb: 1,
              }}
            >
              {isLoading
                ? "Loading..."
                : `Showing ${(currentPage - 1) * currentBatchSize + 1} to ${
                    (currentPage - 1) * currentBatchSize + thumbnails.length
                  } of ${totalCount} images`}

              {getDateRangeTextDisplay ? ` ${getDateRangeTextDisplay}` : "."}
            </Typography>
          )}

          <Grid container spacing={1} sx={{ position: "relative" }}>
            {isLoading ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LDSRingLoader />
              </Box>
            ) : (
              <>
                {thumbnails.length > 0 ? (
                  <>
                    {thumbnails.map((thumbnail, thumbnailIndex) => {
                      return (
                        <ThumbnailCard
                          key={(
                            imageHandler?.getThumbnailImageId(thumbnail) ||
                            thumbnailIndex
                          ).toString()}
                          thumbnail={thumbnail}
                          thumbnailIndex={thumbnailIndex}
                          imageHandler={imageHandler}
                          handleImageClick={handleImageClick}
                          handleImageSelect={handleImageSelect}
                          handleImageFavorite={handleImageFavorite}
                          handleImageHidden={handleImageHidden}
                          getThumbnail={getThumbnail}
                          imagePlaceholder={imagePlaceholder}
                          selectedImageIdLookup={selectedImageIdLookup}
                        />
                      );
                    })}
                  </>
                ) : (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: 200,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="caption">No images found</Typography>
                  </Box>
                )}
              </>
            )}
          </Grid>

          {!isLoading && thumbnails.length > 0 && (
            <Box
              sx={{
                mt: 6,
                mb: 4,

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Pagination
                  color="primary"
                  count={totalPage}
                  page={currentPage}
                  variant="outlined"
                  shape="rounded"
                  size="large"
                  onChange={(e, page) => {
                    handlePageChanged(page);
                  }}
                  renderItem={(params) => {
                    return (
                      <PaginationItem
                        {...params}
                        sx={{
                          border: ({ palette }) =>
                            `1px solid ${alpha(palette.onSecondary.main, 0.1)}`,

                          background: ({ palette }) =>
                            getContrastShade(palette.secondary, "dark"),

                          ":hover": {
                            "&.MuiButtonBase-root": {
                              color: ({ palette }) => palette.onSecondary.main,
                            },
                          },

                          "&.MuiButtonBase-root": {
                            color: ({ palette }) =>
                              alpha(palette.onSecondary.main, 0.8),

                            "&.Mui-selected": {
                              background: ({ palette }) =>
                                alpha(palette.primary.main, 0.6),
                              color: ({ palette }) => palette.onPrimary.main,
                              border: 0,
                            },
                          },
                        }}
                      />
                    );
                  }}
                  sx={{
                    ".MuiPaginationItem-ellipsis": {
                      color: ({ palette }) =>
                        alpha(palette.onSecondary.main, 0.8),
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <Typography variant="caption">Images per page:</Typography>

                <Menu
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  id="basic-menu"
                  anchorEl={batchSizeOptionsEl}
                  open={!!batchSizeOptionsEl}
                  onClose={() => setBatchSizeOptionsEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {GALLERY_BATCH_SIZE_OPTIONS.map((size) => {
                    return (
                      <MenuItem
                        key={size.toString()}
                        selected={size === currentBatchSize}
                        dense
                        onClick={() => {
                          handleBatchSizeChange(size);
                        }}
                      >
                        {size}
                      </MenuItem>
                    );
                  })}
                </Menu>

                <Button
                  size="small"
                  color="secondary"
                  onClick={(e) => setBatchSizeOptionsEl(e.currentTarget)}
                  sx={{
                    border: ({ palette }) =>
                      `1px solid ${alpha(palette.onSecondary.main, 0.1)}`,

                    background: ({ palette }) =>
                      alpha(getContrastShade(palette.secondary, "dark"), 0.7),

                    color: ({ palette }) =>
                      alpha(palette.onSecondary.main, 0.7),

                    minWidth: 0,
                    height: 20,
                    lineHeight: 0,
                    textTransform: "none",
                    mx: 0.5,

                    ":hover": {
                      color: ({ palette }) =>
                        alpha(palette.onSecondary.main, 1),
                      border: ({ palette }) =>
                        `1px solid ${alpha(palette.onSecondary.main, 0.1)}`,
                    },
                  }}
                >
                  {currentBatchSize}
                </Button>
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default ImageViewerGridWindow;
