import { httpsCallable } from "firebase/functions";

import { FirebaseController } from "database/FirebaseController";
import {
  User,
  UserRole,
  AnalyticReportFilterOptions,
  Feedback,
  AnalyticReportSettingOptions,
  AnalyticTopViewedGalleryItem,
  AnalyticTopActiveClientItem,
  AnalyticTopActiveUserItem,
  AnalyticActiveUsersItem,
  AnalyticActionCountItem,
  UnixEpoch,
  WeatherZoneWeather,
  VisualCrossingWeather,
} from "database/DataTypes";

type FunctionName =
  | "createAuthAndUser"
  | "deleteAuthAndUser"
  | "resetPassword"
  | "deleteDoc"
  | "postFeedback"
  | "getAnalyticsReport"
  | "getDetailReport"
  | "getTimeZoneId"
  | "getWzWeather"
  | "getVcWeather";

export type DeleteUserParams = {
  id: number;
};

export type DeleteUserData = {
  status: boolean;
};

export type CreateAuthAndUserParams = {
  user: User;
  userRole: UserRole;
  password: string;
};

export type CreateAuthAndUserData = {
  status: boolean;
  id: number;
};

export type ResetPasswordParams = {
  authUserId: string;
  password: string;
};

export type ResetPasswordData = {
  status: boolean;
  id: number;
};

export type DeleteDocRecursiveParams = {
  collection: string;
  path: string;
};

export type DeleteDocRecursiveData = {
  status: boolean;
};

export type PostFeedbackParams = {
  feedback: Pick<Feedback, "email" | "firstName" | "lastName" | "message">;
  userId: number;
};

export type PostFeedbackData = {
  status: boolean;
  id?: string;
};

export type GetAnalyticsReportParams = {
  filters?: AnalyticReportFilterOptions;
  settings?: AnalyticReportSettingOptions;
};

export type GetAnalyticsReportData = {
  activeUsers: AnalyticActiveUsersItem[];
  topActiveUsers: AnalyticTopActiveUserItem[];
  topActiveClients: AnalyticTopActiveClientItem[];
  topViewedGallery: AnalyticTopViewedGalleryItem[];
  imageViewerActionCount: AnalyticActionCountItem[];
  timelapsePlayerActionCount: AnalyticActionCountItem[];
};

export type GetDetailReportParams = {
  type: "client" | "user";
  filters?: {
    dateRange?: AnalyticReportFilterOptions["dateRange"];
    gaClientId?: string;
    userId?: AnalyticReportFilterOptions["userId"];
    clientId?: AnalyticReportFilterOptions["clientId"];
  };
  settings?: AnalyticReportSettingOptions;
};

export type GetTimeZoneIdParam = {
  long: number;
  lat: number;
};

export type GetTimeZoneIdData = {
  timeZoneId: string;
};

export type GetWzWeatherParam = {
  params: { [key: string]: string };
};

export type GetWzWeatherData = {
  status: boolean;
  data: WeatherZoneWeather;
};

export type GetVcWeatherParam = {
  params: { [key: string]: string };
};

export type GetVcWeatherData = {
  status: boolean;
  data: VisualCrossingWeather;
};

export class _FunctionController {
  private parent!: FirebaseController;

  constructor(parent: FirebaseController) {
    this.parent = parent;
  }

  async createAuthAndUser(params: CreateAuthAndUserParams) {
    return this.invoke<CreateAuthAndUserParams, CreateAuthAndUserData>(
      "createAuthAndUser",
      params,
    );
  }

  async resetPassword(params: ResetPasswordParams) {
    return this.invoke<ResetPasswordParams, ResetPasswordData>(
      "resetPassword",
      params,
    );
  }

  async deleteAuthAndUser(params: DeleteUserParams) {
    return this.invoke<DeleteUserParams, DeleteUserData>(
      "deleteAuthAndUser",
      params,
    );
  }

  async deleteDocRecursive(params: DeleteDocRecursiveParams) {
    return this.invoke<DeleteDocRecursiveParams, DeleteDocRecursiveData>(
      "deleteDoc",
      params,
    );
  }

  async postFeedback(params: PostFeedbackParams) {
    return this.invoke<PostFeedbackParams, PostFeedbackData>(
      "postFeedback",
      params,
    );
  }

  async getAnalyticsReport(params: GetAnalyticsReportParams) {
    return this.invoke<GetAnalyticsReportParams, GetAnalyticsReportData>(
      "getAnalyticsReport",
      params,
    );
  }

  async getDetailReport(params: GetDetailReportParams) {
    return this.invoke<GetDetailReportParams, any>("getDetailReport", params);
  }

  async getTimeZoneId(params: GetTimeZoneIdParam) {
    return this.invoke<GetTimeZoneIdParam, GetTimeZoneIdData>(
      "getTimeZoneId",
      params,
    );
  }

  async getWzWeather(params: GetWzWeatherParam) {
    return this.invoke<GetWzWeatherParam, GetWzWeatherData>(
      "getWzWeather",
      params,
    );
  }

  async getVcWeather(params: GetVcWeatherParam) {
    return this.invoke<GetVcWeatherParam, GetVcWeatherData>(
      "getVcWeather",
      params,
    );
  }

  private async invoke<ReqT = unknown, ResT = unknown>(
    functionName: FunctionName,
    params: ReqT,
  ) {
    return httpsCallable<ReqT, ResT>(
      this.parent.getFunctions(),
      functionName,
    )(params).then((results) => {
      return results.data;
    });
  }
}
