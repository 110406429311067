import { GalleryV2, JobSite, RoleAccessLevel } from "database/DataTypes";
import { useAtomValue } from "jotai";

import { currentUserRoleState } from "states/auth";

const useAccess = () => {
  const currentUserRole = useAtomValue(currentUserRoleState);

  const isAdmin = currentUserRole && currentUserRole.accessLevel === "3";

  const accessableJobSites = currentUserRole?.accessableJobSites || [];

  const isSiteAccessable = (siteId: JobSite["id"] | null) => {
    return (
      isAdmin ||
      (currentUserRole &&
        siteId &&
        currentUserRole.accessableJobSites.includes(siteId))
    );
  };

  const isGalleryRestricted = (galleryId: GalleryV2["id"]) => {
    return (
      !currentUserRole ||
      currentUserRole.restrictedGalleries?.includes(galleryId)
    );
  };

  const isAccessable = (accessableLevels: RoleAccessLevel[] = []) => {
    return (
      currentUserRole && accessableLevels.includes(currentUserRole.accessLevel)
    );
  };

  const isBlocked = (blockedAccessLevels: RoleAccessLevel[] = []) => {
    return (
      !currentUserRole ||
      blockedAccessLevels.includes(currentUserRole.accessLevel)
    );
  };

  return {
    isAccessable,
    isBlocked,
    isAdmin,
    isSiteAccessable,
    accessableJobSites,
    isGalleryRestricted,
    userAccessLevel: currentUserRole?.accessLevel,
  };
};

export default useAccess;
