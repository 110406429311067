import { ReactNode, useRef } from "react";

import {
  Container,
  Box,
  SwitchProps,
  TextFieldProps,
  AutocompleteProps,
  darken,
} from "@mui/material";

import Breadcrumbs, { Breadcrumb } from "components/Breadcrumbs/Breadcrumbs";
import DashboardEditHeader, {
  DashboardEditSliderHeader,
} from "./DashboardEditHeader";
import useOnScreen from "hooks/useOnScreen";

type DashboardEditContainerProps = {
  children: ReactNode;
  breadcrumbs?: Breadcrumb[];
  header?: ReactNode;
  sliderHeader?: ReactNode;

  title: string;
  subtitle?: string;
  onBack?: () => void;
  onDelete?: () => void;
  onSave: () => void;
  isLoading: boolean;
  onDeleteDisabled?: boolean;
};

export const baseSettingTextFieldProps: TextFieldProps = {
  size: "small",
  autoComplete: "new-password",

  sx: {
    minWidth: "50%",
    width: {
      xs: "100%",
      md: "50%",
    },
    // background: "white",

    ".MuiInputAdornment-root": {
      backgroundColor: ({ palette }) => palette.tertiary.light,
      color: ({ palette }) => palette.onTertiary.main,
    },
  },

  InputProps: {
    sx: {
      fontSize: {
        xs: 12,
        sm: 14,
      },
      padding: 0,
    },
  },

  inputProps: {
    sx: {
      padding: "2px 8px",
      backgroundColor: ({ palette }) => palette.surface.main,
      color: ({ palette }) => palette.onSurface.main,

      "&:-webkit-autofill": {
        background: ({ palette }) => `${palette.surface.main} !important`,
        WebkitBoxShadow: ({ palette }) =>
          `0 0 0 1000px ${palette.surface.main} inset`,
        WebkitTextFillColor: "default",
      },

      "&:-webkit-inner-spin-button": {
        background: ({ palette }) => `${palette.surface.main} !important`,

        // "-webkit-appearance": "none",
      },
    },
  },

  FormHelperTextProps: {
    sx: {
      fontSize: "12px !important",
      m: 0,
      ml: 0.5,
    },
  },
};

export const baseSettingSwitchProps: SwitchProps = {
  size: "small",

  sx: {
    height: {
      xs: 22,
      sm: 24,
    },
    ".MuiSwitch-switchBase": {
      color: ({ palette }) => `${palette.surface.main}`,
      "&.Mui-checked": {
        color: ({ palette }) => `${palette.primary.main}`,
      },
    },
    ".MuiSwitch-thumb": {
      width: {
        xs: 14,
        sm: 16,
      },
      height: {
        xs: 14,
        sm: 16,
      },
    },
  },
};

export const baseSettingAutocompleteProps: Partial<
  AutocompleteProps<any, any, any, any>
> = {
  size: "small",
  sx: {
    minWidth: "50%",
    width: {
      xs: "100%",
      md: "50%",
    },

    backgroundColor: ({ palette }) => palette.surface.main,
    color: ({ palette }) => palette.onSurface.main,

    ".MuiInputBase-root": {
      padding: "0 !important",
      paddingRight: "65px !important",
      fontSize: "0.875rem",
    },
    ".MuiInputBase-input": {
      padding: "2px 8px !important",
    },
  },
  slotProps: {
    popper: {
      sx: {
        ".MuiAutocomplete-option": {
          fontSize: "0.875rem !important",
        },
        ".MuiAutocomplete-noOptions": {
          fontSize: "0.875rem !important",
        },
      },
    },
  },
};

const DashboardEditContainer = ({
  children,
  breadcrumbs,
  header,
  sliderHeader,
  title,
  subtitle,
  onDelete,
  onBack,
  onSave,
  isLoading,
  onDeleteDisabled,
}: DashboardEditContainerProps) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const isHeaderVisible = useOnScreen(headerRef, `-100px`);

  return (
    <Box
      sx={({ customConfig, palette }) => ({
        pt: `calc(${customConfig.navbarHeight} + ${customConfig.subNavbarHeight})`,
        color: palette.surface.main,
      })}
    >
      <DashboardEditSliderHeader
        isShow={!isHeaderVisible && Boolean(headerRef.current)}
        title={title}
        subtitle={subtitle}
        onSave={onSave}
        isLoading={isLoading}
      />

      <Container
        fixed
        maxWidth={"xl"}
        sx={{
          height: "100%",
          px: {
            xs: 2,
            sm: 3,
          },
          py: 2,
        }}
      >
        {breadcrumbs && (
          <Breadcrumbs sx={{ pb: 2 }} breadcrumbs={breadcrumbs} />
        )}

        <Box
          sx={{
            backgroundColor: ({ palette }) =>
              darken(palette.tertiary.main, 0.05),
            p: {
              xs: 1,
              sm: 2,
            },
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: ({ palette }) => palette.surface.main,
              borderRadius: 1,
              boxShadow: 1,
              p: {
                xs: 2,
                sm: 2,
                md: 4,
              },
              position: "relative",
            }}
          >
            <DashboardEditHeader
              ref={headerRef}
              title={title}
              subtitle={subtitle}
              onBack={onBack}
              onDelete={onDelete}
              onSave={onSave}
              isLoading={isLoading}
              onDeleteDisabled={onDeleteDisabled}
            />

            <Box>{children}</Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default DashboardEditContainer;
