import { useCallback, useEffect, useMemo, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useAtomValue } from "jotai";
import { searchState } from "states/navbar";
import { currentJobSitesState } from "states/auth";

import useAccess from "hooks/useAccess";

import { Box, Grid, Typography, Card, alpha } from "@mui/material";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";

import { Client, Device, GalleryV2, JobSite } from "database/DataTypes";
import { getDropboxController } from "database/DropboxController";
import { getFirebaseController } from "database/FirebaseController";
import PhotoSentielController from "database/PhotoSentinelController";

import LDSRingLoader from "components/Loaders/LDSRingLoader";
import DashboardContainer from "components/Dashboard/DashboardContainer";
import DashboardHeader, {
  SortType,
} from "components/Dashboard/DashboardHeader";

import { clientListCacheState } from "states/caches";
import useLocalStorage from "hooks/useLocalStorage";
import { useDashboardTracker } from "hooks/eventTracker/useDashboardTracker";

import _ from "lodash";

import pLimit from "p-limit";
import { getContrastShade } from "theme/reliveItTheme";

const limit = pLimit(30);

const SORT_OPTIONS = ["Site"];
const DISPLAY_OPTIONS = ["All Clients", "Active", "Archived"];

const _thumbnailCaches = {};
export const _jobSiteCaches: { [key: number]: JobSite } = {};
export const _clientCaches: { [key: number]: Client } = {};

export const GalleryCard = ({
  gallery,
  galleryIndex,
  isShowClient,
  type = 0,
}: {
  gallery: GalleryV2;
  galleryIndex: number;
  isShowClient: boolean;
  type?: 0 | 1; // 0 - Gallery, 1 - TV Dashboard
}) => {
  const currentJobSites = useAtomValue(currentJobSitesState);
  const clientListCache = useAtomValue(clientListCacheState);

  const [thumbnailSrc, setThumbnailSrc] = useState<string>("");
  const [jobSite, setJobSite] = useState<JobSite | null>(null);
  const [client, setClient] = useState<Client | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const navigate = useNavigate();
  const { isAdmin } = useAccess();

  const isGallery = type === 0;
  const isTVDashboard = type === 1;

  useEffect(() => {
    let intervalId;

    if (gallery) {
      setJobSite(getGalleryJobSite(gallery));
      setClient(getGalleryClient(gallery));

      initThumbnail(gallery);

      if (isTVDashboard) {
        intervalId = setInterval(
          () => {
            initThumbnail(gallery, true);
          },
          10 * 60 * 1000,
        );
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gallery]);

  const initThumbnail = async (gallery, reload = false) => {
    setIsLoading(true);

    return await getGalleryThumbnail(gallery, reload)
      .then((src) => {
        setThumbnailSrc(src);

        if (src) {
          _thumbnailCaches[gallery.id as number] = src;
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getGalleryThumbnail = async (
    gallery: GalleryV2,
    reload: boolean = false,
  ): Promise<string> => {
    if (!reload && (gallery.id as number) in _thumbnailCaches) {
      return _thumbnailCaches[gallery.id as number] || "";
    }

    switch (gallery.galleryImageHost) {
      case "1": {
        const dropboxController = getDropboxController();

        // fetch dropbox with limit to avoid 429 too many requests error
        return await limit(() => {
          return dropboxController
            .getLastImage(gallery.externalHostDirectory, {
              reload,
              objectId: gallery.id as number,
              proxy: gallery.useProxyApi,
            })
            .then(async (image) => {
              if (image) {
                return await dropboxController.getImageThumbnail(
                  image.path_display,
                  { proxy: gallery.useProxyApi },
                );
              } else {
                return "";
              }
            })
            .catch((err) => {
              console.error(err);

              return "";
            });
        });
      }
      case "2": {
        return await PhotoSentielController.getFirstAndLastImage(
          gallery.assignedDevice,
        )
          .then((data) => {
            return data[0].thumb_url;
          })
          .catch((err) => {
            console.error(err);
            return "";
          });
      }
      case "0":
      default: {
        const firebaseController = getFirebaseController();

        return await firebaseController.Image.getLastImage(
          gallery.id as number,
          false,
        )
          .then(async (data) => {
            if (data && data.displayImage) {
              const image = data.displayImage;

              return await firebaseController.Image.getThumbnail(
                image.storageLocation,
                image.fileName,
              ).then((resultURL) => {
                if (resultURL) {
                  return resultURL;
                } else {
                  return image.url;
                }
              });
            } else {
              return "";
            }
          })
          .catch((err) => {
            console.error(err);
            return "";
          });
      }
    }
  };

  const getGalleryJobSite = (gallery: GalleryV2): JobSite | null => {
    if ((gallery.id as number) in _jobSiteCaches) {
      return _jobSiteCaches[gallery.id as number] || "";
    }

    const jobSite = currentJobSites.find(
      (jobSite) => jobSite.id === gallery.jobSite,
    );

    if (jobSite) {
      _jobSiteCaches[gallery.id as number] = jobSite;
    }

    return jobSite || null;
  };

  const getGalleryClient = (gallery: GalleryV2): Client | null => {
    const jobSite = getGalleryJobSite(gallery);
    let client;

    if (jobSite) {
      if ((gallery.id as number) in _clientCaches) {
        return _clientCaches[gallery.id as number] || "";
      }

      client = clientListCache.find((client) => {
        // the first site is primary

        return jobSite.associatedClients[0] === client.id;
      });

      if (client) {
        _clientCaches[gallery.id as number] = client;
      }
    }

    return client || null;
  };

  const Loader = ({ isShow }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          visibility: isShow ? "visible" : "hidden",
        }}
      >
        <LDSRingLoader />
      </Box>
    );
  };

  const ThumbnailPlaceHolder = () => {
    const isImageRendering = thumbnailSrc && !isLoaded;
    const isImageBroken = !isLoading && !thumbnailSrc;
    const isReloadingImage = isLoading && thumbnailSrc;

    return (
      <Box
        sx={{
          aspectRatio: 185 / 139,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          backgroundColor: ({ palette }) =>
            isImageBroken
              ? alpha(palette.secondary.main, 0.3)
              : isReloadingImage
              ? alpha(palette.secondary.main, 0.1)
              : "none",
        }}
      >
        {isLoading || isImageRendering ? (
          <Loader isShow={isLoading || isImageRendering} />
        ) : (
          <>
            {isImageBroken && (
              <BrokenImageIcon
                fontSize="large"
                sx={{ color: ({ palette }) => palette.onSurface.main }}
              />
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <Card
        sx={{
          backgroundColor: ({ palette }) => palette.tertiary.main,
          color: ({ palette }) => palette.onTertiary.main,

          width: "100",
          height: "100%",
          borderRadius: 1,
          transition: ({ transitions }) =>
            transitions.create("all", {
              duration: transitions.duration.shorter,
            }),
          boxShadow: 1,
          p: 1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          ":hover": isGallery
            ? {
                transform: "scale(1.01)",
                boxShadow: 2,
              }
            : {},
        }}
      >
        <Box
          sx={{
            flex: 1,
            cursor: isGallery ? "pointer" : "auto",
            backgroundColor: ({ palette }) => palette.surface.main,
            color: ({ palette }) => palette.onSurface.main,

            borderRadius: 1,
            py: 1,
            px: 1,
            position: "relative",
          }}
          onClick={() => {
            if (isGallery) {
              navigate(
                isAdmin
                  ? `/galleries/${gallery.id}`
                  : `/galleries/${gallery.id}/image-viewer`,
              );
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ overflow: "hidden" }}>
              {isShowClient && (
                <Typography
                  sx={{
                    lineHeight: 1,
                    fontSize: 16,
                    // fontWeight: "bold",
                    mb: 0.5,
                  }}
                >
                  {client?.name || "-"}
                </Typography>
              )}

              {isGallery ? (
                <>
                  <Typography
                    sx={{
                      lineHeight: 1,
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {jobSite?.name || "-"}
                  </Typography>

                  <Typography
                    sx={{
                      lineHeight: 1,
                      fontSize: 14,
                      mt: 0.5,
                    }}
                  >
                    {gallery.galleryName}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      lineHeight: 1,
                      fontSize: 16,
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {[jobSite?.name, gallery.galleryName]
                      .filter((v) => !!v)
                      .join(" - ")}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            // snappy's ratio
            aspectRatio: 185 / 139,
            mt: 1,
            position: "relative",
            cursor: isGallery ? "pointer" : "auto",

            borderRadius: 1,
            overflow: "hidden",
          }}
          onClick={() => {
            if (isGallery) {
              navigate(`/galleries/${gallery.id}/image-viewer`);
            }
          }}
        >
          <ThumbnailPlaceHolder />

          {thumbnailSrc && (
            <Box
              component={"img"}
              crossOrigin="anonymous"
              width={"100%"}
              onLoad={() => setIsLoaded(true)}
              src={thumbnailSrc}
              sx={{
                display: "block",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </Box>
      </Card>
    </>
  );
};

type GalleryGridWindowProps = {
  type?: 0 | 1;
  gridColumns?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
};

type GetGalleryListOptions = {
  isShowAllClient?: boolean;
  isShowArchived?: boolean;
  isShowActive?: boolean;
};

const GalleryGridWindow = ({
  type = 0,
  gridColumns,
}: GalleryGridWindowProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [galleryList, setGalleryList] = useState<GalleryV2[]>([]);

  const [displayGalleryList, setDisplayGalleryList] = useState<GalleryV2[]>([]);
  const [deviceLookup, setDeviceLookup] = useState<{ [key: string]: Device }>(
    {},
  );

  const [currentSortType, setCurrentSortType] = useState<SortType>({
    type: SORT_OPTIONS[0],
    isAsc: true,
  });

  const [currentDisplayOptions, setCurrentDisplayOptions] = useLocalStorage<
    string[]
  >("gallery-display-type", []);

  const currentJobSites = useAtomValue(currentJobSitesState);

  const search = useAtomValue(searchState);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isAdmin, isGalleryRestricted } = useAccess();

  const { trackAction } = useDashboardTracker("galleries", true);

  const clientListCache = useAtomValue(clientListCacheState);

  const getCurrentGalleryDisplayOptions = useCallback(
    (displayOptions?: string[]) => {
      const options: GetGalleryListOptions = {};

      if (isAdmin) {
        (displayOptions || currentDisplayOptions).forEach((option) => {
          if (option === "All Clients") {
            options.isShowAllClient = true;
          } else if (option === "Archived") {
            options.isShowArchived = true;
            options.isShowActive = undefined;
          } else if (option === "Active") {
            options.isShowArchived = false;
            options.isShowActive = undefined;
          }
        });
      } else {
        const archiveParam = !!searchParams.get("archive");

        if (archiveParam) {
          options.isShowArchived = archiveParam;
          options.isShowActive = undefined;
        } else {
          options.isShowArchived = archiveParam;
          options.isShowActive = undefined;
        }
      }

      return options;
    },
    [currentDisplayOptions, searchParams, isAdmin],
  );

  useEffect(() => {
    // Might not be a good idea, but it's to prevent invalid param causing the navbar active state not display correctly
    const archiveParam = searchParams.get("archive");

    if (archiveParam && archiveParam !== "true") {
      navigate("/galleries");
    }

    let displayOptions;

    if (currentDisplayOptions.length === 0) {
      const displayOptions = isAdmin ? ["Active", "All Clients"] : [];

      setCurrentDisplayOptions(displayOptions);
    }

    getGalleryList(getCurrentGalleryDisplayOptions(displayOptions));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, currentJobSites, clientListCache]);

  useEffect(() => {
    handleSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.onSearch]);

  const getGalleryList = async ({
    isShowAllClient,
    isShowArchived,
    isShowActive,
  }: GetGalleryListOptions = {}) => {
    setIsLoading(true);

    const firebaseController = getFirebaseController();

    const getGalleries = async () => {
      return await firebaseController.Gallery.getGalleries({
        jobSiteIds: isShowAllClient
          ? undefined
          : currentJobSites.map((jobSite) => jobSite.id),
        isArchived: isShowArchived,
        isActive: isShowActive,
        isVisible: isAdmin ? undefined : true,
      });
    };

    await getGalleries()
      .then(async (data) => {
        const galleryList = data.filter((g) => !isGalleryRestricted(g.id));

        if (isShowAllClient) {
          await getAllGalleryJobSites(galleryList);
        } else {
          galleryList.forEach((gallery) => {
            getGalleryJobSite(gallery);
          });
        }

        const sortedList = handleSort(
          isShowAllClient ? "Client" : "Site",
          true,
          galleryList,
        );

        setGalleryList(sortedList);
        // setDisplayGalleryList(sortedList);
      })
      .catch((err) => {
        console.error(err);
      });

    setIsLoading(false);
  };

  const getAllGalleryJobSites = async (galleries: GalleryV2[]) => {
    // #TODO: we might have all job sites caches already

    return await getFirebaseController()
      .JobSite.getJobSites()
      .then((jobSites) => {
        const jobSiteMapper: { [id: number]: JobSite } = {};

        jobSites.forEach((jobSite) => {
          jobSiteMapper[jobSite.id as number] = jobSite;
        });

        galleries.forEach((gallery) => {
          const galleryJobSite = jobSiteMapper[gallery.jobSite as number];

          _jobSiteCaches[gallery.id as number] = galleryJobSite;

          const client = clientListCache.find((client) => {
            // get primary client
            return galleryJobSite.associatedClients[0] === client.id;
          });

          if (client) {
            _clientCaches[gallery.id as number] = client;
          }
        });

        return jobSites;
      });
  };

  const getGalleryJobSite = (gallery: GalleryV2): JobSite | null => {
    if ((gallery.id as number) in _jobSiteCaches) {
      return _jobSiteCaches[gallery.id as number] || "";
    }

    const jobSite = currentJobSites.find(
      (jobSite) => jobSite.id === gallery.jobSite,
    );

    if (jobSite) {
      _jobSiteCaches[gallery.id as number] = jobSite;
    }

    return jobSite || null;
  };

  const handleSort = (
    sortType: string,
    sortAsc?: boolean,
    list?: GalleryV2[],
  ) => {
    const isAsc =
      sortAsc !== undefined
        ? sortAsc
        : currentSortType.type === sortType
        ? !currentSortType.isAsc
        : false;
    const order = isAsc ? "asc" : "desc";

    setCurrentSortType({
      type: sortType,
      isAsc,
    });

    let newGalleryList = _.cloneDeep(list || displayGalleryList);

    switch (sortType) {
      case "Site":
        newGalleryList = _.orderBy(
          newGalleryList,
          [
            (gallery) =>
              _jobSiteCaches[gallery.id as number]?.name?.toLowerCase() || "-",
            "galleryName",
          ],
          [order, "asc"],
        );

        trackAction("sort site");
        break;

      case "Client":
        newGalleryList = _.orderBy(
          newGalleryList,
          [
            (gallery) => {
              return (
                _clientCaches[gallery.id as number]?.name?.toLowerCase() || ""
              );
            },
            (gallery) =>
              _jobSiteCaches[gallery.id as number]?.name?.toLowerCase() || "",
            (gallery) => gallery.galleryName?.toLowerCase() || "",
          ],
          [order, "asc", "asc"],
        );

        trackAction("sort site");
        break;

      default:
        break;
    }

    setDisplayGalleryList(newGalleryList);

    return newGalleryList;
  };

  const isShowAll = useMemo(
    () => currentDisplayOptions.includes(DISPLAY_OPTIONS[0]),
    [currentDisplayOptions],
  );

  const handleDisplay = async (displayOption: string) => {
    // const newCurrenDisplaytOptions = _.xor(
    //   [displayOption],
    //   [...currentDisplayOptions],
    // );

    let newCurrenDisplaytOptions = [...currentDisplayOptions];

    const existed = newCurrenDisplaytOptions.indexOf(displayOption) !== -1;

    if (existed) {
      if (!["Active", "Archived"].includes(displayOption)) {
        newCurrenDisplaytOptions = newCurrenDisplaytOptions.filter((option) => {
          return option !== displayOption;
        });
      }
    } else {
      newCurrenDisplaytOptions.push(displayOption);

      newCurrenDisplaytOptions = newCurrenDisplaytOptions.filter((option) => {
        if (displayOption === "Active") {
          return option !== "Archived";
        } else if (displayOption === "Archived") {
          return option !== "Active";
        } else {
          return true;
        }
      });
    }

    setCurrentDisplayOptions(newCurrenDisplaytOptions);

    await getGalleryList(
      getCurrentGalleryDisplayOptions(newCurrenDisplaytOptions),
    );
  };

  const handleSearch = () => {
    let newGalleryList = _.cloneDeep(galleryList);

    if (search.value) {
      trackAction("search");

      const searchTexts = search.value.split(" ");

      newGalleryList = newGalleryList.filter((gallery) => {
        const site = _jobSiteCaches[gallery.id as number]?.name?.toLowerCase();
        const client = _clientCaches[gallery.id as number]?.name?.toLowerCase();

        const fields = [
          gallery.galleryName.toLowerCase(),
          site || "",
          client || "",
        ];

        return searchTexts.every((s) => {
          return fields.some((f) => f.includes(s.toLowerCase()));
        });
      });
    }

    setDisplayGalleryList(newGalleryList);
  };

  const isTVDashboard = type === 1;

  const gridColumnProps = gridColumns || {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3,
  };

  return (
    <DashboardContainer
      NavbarProps={{ isShowClient: true }}
      disabled={isTVDashboard}
    >
      <DashboardHeader
        disabled={isTVDashboard}
        sortOptions={
          isAdmin && isShowAll ? [...SORT_OPTIONS, "Client"] : SORT_OPTIONS
        }
        displayOptions={isAdmin ? DISPLAY_OPTIONS : undefined}
        handleSort={handleSort}
        handleDisplay={handleDisplay}
        currentSortType={currentSortType}
        currentDisplayOptions={currentDisplayOptions}
        buttons={[
          {
            label: "+ Add New Gallery",
            onClick: () => navigate("./new"),
            isHidden: !isAdmin,
          },
        ]}
      />

      {!isTVDashboard && (
        <Grid item sx={{ mb: 0.5 }}>
          <Typography variant="caption" textAlign={"center"}>
            {isLoading
              ? "Loading..."
              : `${displayGalleryList.length} galleries found.`}
          </Typography>
        </Grid>
      )}

      <Grid container spacing={1}>
        {displayGalleryList.length > 0 ? (
          <>
            {displayGalleryList.map((gallery, galleryIndex) => {
              return (
                <Grid key={gallery.id} item {...gridColumnProps}>
                  <GalleryCard
                    key={gallery.id}
                    gallery={gallery}
                    galleryIndex={galleryIndex}
                    isShowClient={isShowAll}
                    type={type}
                  />
                </Grid>
              );
            })}
          </>
        ) : isTVDashboard ? (
          <Grid item>
            <Typography
              variant="caption"
              textAlign={"center"}
              sx={{ color: "white !important" }}
            >
              {isLoading ? "Loading..." : "No gallery found."}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </DashboardContainer>
  );
};

export default GalleryGridWindow;
